<template>
    <Form @submit="submit">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            Import specification names
          </h5>
          {{ template }}
          <el-link :href="template" type="primary">Download the import template</el-link>
        </div>
        <div class="card-body">
          <div class="row">
              <div class="col-md-12">
                <argon-input
                :multiple="false"
                  type="upload"
                  @input="getFiles($event)"
                  :value="form.files"
                  :fileTypes="['xlsx']"
                ></argon-input>
              </div>
         
            </div>

          <div style="text-align: right;">
            <el-button type="primary" native-type="submit" size="small" :disabled="!form.files.length">Submit</el-button>
          </div>
        </div>
      </div>
    </Form>
</template>
<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { SupplierhubSpec } from '@/api_services/supplierhub_spec'
import ArgonInput from '../../../common/Input/ArgonInput.vue'


export default {
  components: {
    ArgonInput, Form, Field, ErrorMessage
  },
  props:
     {
      
    spec: {
      type: Object,
      required: false
    },
    template: {
      type: String,
      required: false
    },
    lot: {
      type: Object,
      required: false
    },
  },
  data() {
    return {
      form: {
        files: []
      },
      loading: false
    }
  },
  beforeUnmount() {
    this.form = {}
  },
  watch: {
    spec: function () {
      this.form = Object.assign({}, this.spec)
    }
  },
  created: function () {
    if (this.spec) this.form = Object.assign({}, this.spec)
  },
  methods: {
    getFiles(files) {
      if(files.length > 0){
        let newFiles = []
        files.forEach(file => {
          newFiles.push(file.raw)
        });
        this.form.files = newFiles
      }
    },
    getForm() {
      let formData = new FormData()
      formData.append('file', this.form.files[0])
      formData.append('lot_id', this.lot.id)
      return formData
    },
    submit() {
      this.$loading = true

       SupplierhubSpec.import(this.getForm())
        .then(result => {
          this.$toast.success('Spec names has been imported')
          this.$emit('close', true)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
