<template>
  <div v-loading="loading">
    <el-alert :title="''" :type="selected.status == 1 ? '' : 'info'" :effect="selected.status == 1 ? 'light' : ''"
      :closable="false">
      <div v-html="selected.name" :class="selected.status == 1 ? 'text-success':'text-info'"></div>
      <div style="font-size: 10px">TYPE: {{ selected.type }}
        <span>({{ getThresholdText(selected) }})</span>
      </div>

      <span class="text-xs">{{ showUserInformation() }}</span>
      <span class="text-xs">{{ selected.status == 1 ? ' (Approved)' : ' (Suggestion)' }}
      </span>

      <div>
        <el-button v-if="canApprove()" size="small" @click="handleApprove()" type="success" plain v-tooltip="'Approve'"><i
            class="fa fa-check"></i></el-button>
        <el-button v-if="canComment()" size="small" @click="openCommentsDrawer" type="info" plain>
          <i class="fa-solid fa-comment-dots" style="margin-right: 5px"></i>
          {{ selected.total_comments > 0 ? ' View Comments' : ' Add Comment' }}
        </el-button>

        <el-button v-if=" 
        (itt_framework.is_deadline_passed && $store.state.isUserAnOriginator() && !is_parent_archive) ||
        (!itt_framework.is_deadline_passed && !is_parent_archive && savedUser.is_supplier_hub_admin)
        
      " size="small" @click="handleRemoveSpec()" type="danger" plain v-tooltip="'Delete'"><i
            class="fa fa-trash"></i></el-button>
      </div>
    </el-alert>

    <div style="padding-left: 20px;padding-top: 5px;text-align: left;color: #afacac;"
      v-if="selected.total_comments">
      {{ selected.total_comments }} comment{{
      selected.total_comments > 1 ? 's' : ''
      }}
    </div>

    <el-drawer :title=" !selected.kind ? 'Specification Comments' : 'Social Value Comments'" size="50%"
      :with-header="true" :append-to-body="true" v-model="showCommentsDrawer" direction="rtl" :destroy-on-close="true">
      <div class="p-3">
        <div v-html="selected.name"></div>
      </div>

      <supplierhub-spec-comment-form :spec="selected" @close="addComment($event)"
        v-if="!is_parent_archive"></supplierhub-spec-comment-form>

      <div class="p-3">
        <span v-if="comments.length > 0"><b>Comments</b></span>
        <el-alert :title="''" type="info" :closable="false" v-for="comment in comments" :key="comment.id"
          style="margin-top: 5px;width: 100%">
          <div style="font-weight: bold;">{{ comment.user.name }}</div>
          <div v-html="comment.name"></div>
          <div>
            <span class="text-xs px-1">{{ showUserInformation(comment) }}</span>
            <el-button v-if="allowCreate()" size="small" @click="handleRemove(comment)" type="danger" plain
              v-tooltip="'Remove Comment'"><i class="fa-solid fa-xmark"></i></el-button>
          </div>
          <div></div>
        </el-alert>
        <div style="color: #afacac;margin-top: 5px;" v-if="
            fullView && !no_more && comments.length < selected.total_comments
          ">
          <el-link style="cursor: pointer;" @click="loadMore()">More comments</el-link>
        </div>
        <div style="color: #afacac;margin-top: 5px;font-size:12px" v-if="no_more || comments.length >= selected.total_comments">
          No more comments
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import * as moment from 'moment'
import SupplierhubSpecCommentForm from './SupplierhubSpecCommentForm.vue'
import {
  SupplierhubSpec
} from '@/api_services/supplierhub_spec'
import {
  mapState
} from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'supplierhub-spec-view',
  components: {
    SupplierhubSpecCommentForm
    // SupplierhubSpecView: () => import('./SupplierhubSpecView.vue')
  },
  props: {
    itt_framework: {
      type: Object,
      required: true
    },
    is_deadline_passed: {
      default: false,
      required: false
    },
    if_user_joined: {
      default: false,
      required: false
    },
    spec: {
      type: Object,
      required: true
    },
    showUserInfo: {
      type: Boolean,
      default: false
    },
    fullView: {
      type: Boolean,
      default: true
    },
    if_pin_notice: {
      type: Number,
      required: false,
      default: 0
    },
    is_parent_archive: {
      required: false,
      default: 0
    }
  },
  computed: {
    ...mapState(['savedUser']),
    this_user() {
      return this.$store.state.savedUser 
    }
  },
  data() {
    return {
      company_ids: [],
      showCommentsDrawer: false,
      selected: null,
      showReply: false,
      comments: [],
      no_more: false,
      showDialog: false,
      page: 1,
      per_page: 10,
      loading: true
    }
  },
  created: function () {
    this.selected = Object.assign({}, this.spec)
    this.initComments()

    for (const [key, value] of Object.entries(this.this_user.companies)) {
      this.company_ids.push(value.id)
    }
  },
  methods: {
    canComment() {
      let result =
        (this.itt_framework.is_deadline_passed && $store.state.isUserAnOriginator() && !this.is_parent_archive) ||
        (!this.itt_framework.is_deadline_passed && !this.is_parent_archive && this.savedUser.is_supplier_hub_admin)

      if (this.allowCreate() && this.$store.state.isUserASupplier() ) result = true

      return true
    },
    canApprove() {

      let result = this.selected.status == 0 && (this.selected.status == 0 &&

        (this.itt_framework.is_deadline_passed && $store.state.isUserAnOriginator() && !this.is_parent_archive) ||
        (!this.itt_framework.is_deadline_passed && !this.is_parent_archive && this.savedUser.is_supplier_hub_admin)
      )

      if (this.allowCreate() && this.selected.status == 0) result = true

      return result
    },
    allowCreate() {
      let condition = (this.itt_framework.is_deadline_passed && this.$store.state.isUserAnOriginator() && !this.itt_framework.is_archived) ||
        ((!this.itt_framework.is_deadline_passed && !this.itt_framework.is_archived && this.savedUser.is_supplier_hub_admin) &&
          (this.$store.state.isUserASupplier() && this.if_pin_notice == 1) || (!this.$store.state.isUserASupplier())
        )
      if (this.itt_framework && this.if_pin_notice) {
        if (this.itt_framework.read_only_from_status) return false
      }
      return condition
    },
    checkIfNotJoined(){
      if(
        !this.itt_framework.if_user_joined && 
        this.itt_framework?.user?.id != this.this_user.id &&
        this.this_user?.company?.id != this.itt_framework?.company?.id &&
        !this.company_ids.includes(this.itt_framework?.company?.id)
      ){
        return true
      }
      return false
    },
    openCommentsDrawer() {
      let type = "itt"
      if (this.if_pin_notice == 1) {
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to add or view a comment!",
          icon: 'warning',
        })
        return false
      }

      this.showCommentsDrawer = true
    },
    handleApprove() {
      let type = "itt"
      if (this.if_pin_notice == 1) {
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to approve this comment!",
          icon: 'warning',
        })
        return false
      }

      let k = this.selected && this.selected.kind ? "social value" : 'spec'

      Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure to approve this '+k+' ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, approve it!'
      }).then(result => {
        if (result.isConfirmed) {
          this.onApproveSpec()
        }
      })
    },
    onApproveSpec() {
      let k = this.selected && this.selected.kind ? "Social value" : 'Spec'

      SupplierhubSpec.approve(this.selected.id)
        .then(result => {
          this.$toast.success(k+' has been approved')
          this.selected = Object.assign({}, result.data.spec)
          this.loading = false
        })
        .catch(error => {
          this.$toast.error('An error occured while saving')
          console.error('error:', error.response.data)
        })
        .finally(() => {})
    },
    initComments() {
      this.comments = []

      if (this.fullView) {
        this.comments = []
        this.getComments()
      } else {
        let self = this
        this.selected.first_3_comments.forEach(comment => {
          self.comments.push(comment)
        })
      }
    },
    handleRemove(comment) {

      Swal.fire({
        title: 'Are you sure?',
        text: 'Once deleted, this comment will be removed permanently!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then(result => {
        if (result.isConfirmed) {
          this.loading = true
          SupplierhubSpec.removeComment(this.selected.id, {
              comment_id: comment.id
            })
            .then(result => {
              Swal.fire('Deleted!', 'Your comment has been removed.', 'success')
              this.selected = Object.assign({}, result.data.spec)
              this.initComments()
            })
            .catch(error => {
              Swal.fire(
                'Error!',
                'An error occurred while removing the comment.',
                'error'
              )
              console.error('Error:', error.response.data)
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getComments() {
      let params = {
        page: this.page,
        per_page: this.per_page
      }
      this.loading = true
      SupplierhubSpec.getComments(this.selected.id, params)
        .then(result => {
          if (result.data.comments) {
            let self = this
            result.data.comments.forEach(function (comment) {
              self.comments.push(comment)
            })
            if (!result.data.comments.length) this.no_more = true
          }
          this.loading = false
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching comments')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadMore() {
      this.page += 1
      this.getComments()
    },
    handleClose() {
      this.showDialog = false
    },
    showFullView() {
      this.showDialog = true
    },
    addComment(comment) {
      if (comment) this.comments.unshift(comment)
    },
    showUserInformation(model) {
      let user = model ? model.user : this.selected.user
      let date = model ? model.created_at : this.selected.created_at
      return (
        (model ? '' : user.name + ' | ') +
        user.company_name +
        ' | ' +
        this.getDate(date)
      )
    },
    getDate(date) {
      return moment(date).format('MM/DD/YYYY HH:mm:ss')
    },
    getThresholdText(selected) {
      let message = ''

      if (selected.type == 'choice') {
        message = "must be set to " + (selected.threshold_value ? 'YES' : 'NO')
      } else {
        if (selected.direction == '=') message += ' equal to '
        if (selected.direction == '<=') message += ' less than or equal to '
        if (selected.direction == '>=') message += ' greater than or equal to '
        message += selected.threshold_value + ' ' + selected.unit
      }


      return message 
    },
    handleRemoveSpec() {
      let type = "itt"
      if (this.if_pin_notice == 1) {
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to remove a spec!",
          icon: 'warning',
        })
        return false
      }

      let k = this.selected && this.selected.kind ? "Social value" : 'Spec'


      Swal.fire({
        title: 'Are you sure?',
        text: 'Once deleted, this '+k+' will be removed permanently!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then(result => {
        if (result.isConfirmed) {
          this.loading = true

          let type = "itt"
          if(this.if_pin_notice == 1){
            type = "pin"
          }

          SupplierhubSpec.delete(this.selected.id, type)
            .then(result => {
              Swal.fire('Deleted!', k+' has been removed.', 'success')
              this.$emit('close', true)
            })
            .catch(error => {
              Swal.fire(
                'Error!',
                'An error occurred while removing.',
                'error'
              )
              console.error('Error:', error)
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style scoped>
.completed {
  background: #d3ffe3;
}
</style>
