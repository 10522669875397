<template>
  <div>
    <div style="text-align: left;" class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-2  mb-2">
            <div class="form-group">
              <label>Status:</label>
              <select v-model="form.status" class="form-select form-select-sm">
                <option v-for="status in $store.state.statuses" :key="status.id" :value="status.value">{{ status.text }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-10 mb-2">
            <div class="row">
              <div class="col-md-2">
                <label>Date Type:</label>
                <select v-model="date_type" class="form-select form-select-sm">
                  <option value="default">Default</option>
                  <option value="custom">Custom</option>
                </select>
              </div>
              <div class="col-md-10">
                <div v-if="date_type == 'default'" class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                        <label>&nbsp;</label>
                        <select name="" id="" v-model="default_date" class="form-select form-select-sm">
                          <option value="latest_month">Latest Month</option>
                          <option value="previous_month">Previous Month</option>
                          <option value="latest_year">Latest Year</option>
                          <option value="previous_year">Previous Year</option>
                        </select>
                      </div>
                  </div>
                </div>
                <div v-if="date_type == 'custom'" class="row">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>From:</label>
                        <select name="" id="" v-model="form.year_start" class="form-select form-select-sm">
                          <option v-for="year in years" :key="year.id" :value="year.value">{{ year.text }}</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="form-group">
                        <label>&nbsp;</label>
                        <select name="" id="" v-model="form.month_start" class="form-select form-select-sm">
                          <option v-for="month in months" :key="month.id" :value="month.value">{{ month.text }}</option>
                        </select>
                      </div>
                    </div>

                    <div class="ms-3 col-md-2">
                      <div class="form-group">
                        <label>To:</label>
                        <select name="" id="" v-model="form.year_end" class="form-select form-select-sm">
                          <option v-for="year in years" :key="year.id" :value="year.value">{{ year.text }}</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="form-group">
                        <label>&nbsp;</label>
                        <select name="" id="" v-model="form.month_end" class="form-select form-select-sm">
                          <option v-for="month in months" :key="month.id" :value="month.value">{{ month.text }}</option>
                        </select>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row" style="margin-bottom: 10px">
          <div class="col-md-2">
            <button class="btn bg-gradient-secondary btn-sm" type="button" @click="search()" style="width: 100%"
              :disabled="loading_report_queue">
              {{ loading_report_queue ? 'Searching' : 'Search' }}
            </button>
          </div>
          <div class="col-md-2" v-if="$store.state.isAllowed('cost_data', 'update')">
            <button class="btn bg-gradient-secondary btn-sm" type="button" @click="getReport()" style="width: 100%"
              :disabled="loading_report_queue">
              Queue Report
            </button>
          </div>
          <div class="col-md-2">
            <button class="btn bg-gradient-secondary btn-sm" type="button" @click="exportReport()" style="width: 100%"
              :disabled="loading_report_queue">
              Export Report
            </button>
          </div>
          <div class="col-md-2" v-if="$store.state.isAllowed('cost_data', 'delete')">
            <button class="btn bg-gradient-danger btn-sm" type="button" @click="showRemove()" style="width: 100%">
              Delete Transactions
            </button>
          </div>
          <div class="col-md-12">
            <span class="text-sm">Found {{ meta.total }} results</span>
          </div>
        </div>
        <div class="table-responsive" v-if="meta.total">
          <table class="table table-borderless table-striped">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder" v-for="field in fields"
                  :key="field.id">
                  {{ field.replace('_', ' ').replace('_', ' ') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-xxs" v-for="card_transaction in card_transactions" :key="card_transaction.id">
                <td>{{ card_transaction.submit_date }}</td>
                <td>{{ card_transaction.dba_name }}</td>
                <td>{{ card_transaction.card_type }}</td>
                <td>{{ card_transaction.transaction_type }}</td>
                <td>{{ card_transaction.plan_code_description }}</td>
                <td class="right-text">{{ card_transaction.processed_transaction_amount }}</td>
                <td class="right-text">{{ card_transaction.ic_expenses }}</td>
                <td class="right-text">{{ card_transaction.interchange_fee_cost }}</td>
                <td class="right-text">{{ card_transaction.assessment_fee_cost }}</td>
                <td class="right-text">{{ card_transaction.card_processing_fee_cost }}</td>
                <td class="right-text">{{ card_transaction.total_cost }}</td>
                <td class="right-text">{{ card_transaction.terminal_id }}</td>
                <td class="right-text">{{ getEffectiveRate(card_transaction) }} %</td>
                <td>{{ getConcatenatedResult(card_transaction) }}</td>
                <td>{{ card_transaction.card_type_translation }}</td>
                <td class="right-text">
                  {{ $store.state.roundOffAmount(card_transaction.old_rate,4)}}%
                </td>
                <td class="right-text">{{ card_transaction.old_charge }}</td>
                <td class="right-text">{{ card_transaction.saving }}</td>
                <td class="right-text">{{ card_transaction.expected_rate }}</td>
                <td class="right-text">{{ card_transaction.difference_rate }} %</td>
                <td class="right-text">{{ card_transaction.difference }}</td>
                <td class="right-text">{{ card_transaction.flagged_cost_diff }}</td>
                <td class="right-text">{{ card_transaction.flagged_rate_diff }}</td>
                <td class="right-text">{{ card_transaction.flagged_cost }}</td>
                <td class="right-text">{{ card_transaction.flagged_value }}</td>
                <td>{{ getStatusLabel(card_transaction.status) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container text-center">
          <pagination :meta="meta" v-on:update="getCardTransactions()" />
        </div>
      </div>
      <div class="col-md-6" v-if="original">
        <el-card :header="selectedName(original)" class="my-3 border-0">
          <b-form @submit="onSubmit" @reset="onCancel">
            <b-form-group id="name" label="Name" label-for="input-name" valid-feedback=""
              :invalid-feedback="invalidFeedback('name')" :state="validation('name')">
              <b-form-input id="input-name" v-model="selected.name" :state="validation('name')" trim></b-form-input>
            </b-form-group>

            <b-button type="submit" :disabled="!validateAll">Submit</b-button>
            <b-button type="reset" variant="danger">Cancel</b-button>
          </b-form>
        </el-card>
      </div>
    </div>

    <el-dialog id="modal-remove" v-model="dialogVisible" title="" width="500">
      <div>
        <delete-card-transactions v-if="dialogVisible" :startDate="getSubmitDate()" :endDate="getSubmitDate('end')"
          :category="category" @update="onDeleteCardTransactions()"></delete-card-transactions>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  CardTransaction
} from '@/api_services/card_transaction'
import {
  CostDataExport
} from '@/api_services/cost_data_export'
import DeleteCardTransactions from './DeleteCardTransactions'
import Modal from '@/components/common/Modal'
import * as moment from 'moment'

import store from '@/store/index.js'

export default {
  watch: {
    category: {
      handler(value) {
        this.getCardTransactions()
      },
      deep: true
    }
  },
  props: {
    category: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      default_date: "latest_month",
      date_type: "default",
      dialogVisible: false,
      card_transactions: [],
      fields: [
        'submit_date',
        'dba_name',
        'card_type',
        'transaction_type',
        'plan_code_description',
        'processed_transaction_amount',
        'ic_expenses',
        'interchange_fee_cost',
        'assessment_fee_cost',
        'card_processing_fee_cost',
        'total_cost',
        'terminal_id',
        'effective_rate',
        'concatenated_card_type',
        'card_type_translation',
        'old_rate',
        'old_charge',
        'saving',
        'expected_rate',
        'difference_rate',
        'difference',
        'flagged_cost_diff',
        'flagged_rate_diff',
        'flagged_cost',
        'flagged_value',
        'status'
      ],
      selected: null,
      original: null,
      validate: ['name'],
      to_remove: null,
      loading_report_queue: false,
      term: null,
      form: {
        status: null
      },
      loading: false,
      per_page: 100,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      months: [],
      years: [{
        value: 0,
        text: '20'
      }],
      deletCardTransactionModal: null
    }
  },
  components: {
    DeleteCardTransactions,
    Modal
  },
  created: function () {
    this.generateYearsAndMonths()
    this.getCardTransactions()
  },
  methods: {
    onDeleteCardTransactions() {
      this.dialogVisible = false
    },
    generateYearsAndMonths() {
      let date = new Date()
      let year = date.getFullYear()
      this.months = [{
          value: 0,
          text: 'Jan'
        },
        {
          value: 1,
          text: 'Feb'
        },
        {
          value: 2,
          text: 'Mar'
        },
        {
          value: 3,
          text: 'Apr'
        },
        {
          value: 4,
          text: 'May'
        },
        {
          value: 5,
          text: 'Jun'
        },
        {
          value: 6,
          text: 'Jul'
        },
        {
          value: 7,
          text: 'Aug'
        },
        {
          value: 8,
          text: 'Sep'
        },
        {
          value: 9,
          text: 'Oct'
        },
        {
          value: 10,
          text: 'Nov'
        },
        {
          value: 11,
          text: 'Dec'
        }
      ]

      this.years = []
      this.years.push({
        value: year,
        text: year
      })
      this.years.push({
        value: year - 1,
        text: year - 1
      })
      this.years.push({
        value: year - 2,
        text: year - 2
      })
      this.years.push({
        value: year - 3,
        text: year - 3
      })
      this.years.push({
        value: year - 4,
        text: year - 4
      })
      this.years.push({
        value: year - 5,
        text: year - 5
      })

      this.form.year_start = year
      this.form.month_start = date.getMonth()

      this.form.year_end = year
      this.form.month_end = date.getMonth()
    },
    getDifferenceAmount(item) {
      return (
        item.total_cost -
        (item.expected_rate / 100) * item.processed_transaction_amount
      ).toFixed(5)
    },
    getDifferenceRate(item) {
      return this.getEffectiveRate(item) - item.expected_rate
    },
    getSaving(item) {
      return this.getOldCharge(item) - item.processed_transaction_amount
    },
    getOldCharge(item) {
      return item.old_rate * item.processed_transaction_amount
    },
    getEffectiveRate(item) {
      return (
        (item.total_cost / item.processed_transaction_amount) *
        100
      ).toFixed(4)
    },
    getConcatenatedResult(item) {
      return item.card_type + '' + item.plan_code_description
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.status === 1) return 'table-success'
    },
    getStatusLabel(status) {
      switch (status) {
        case 1:
          return 'VALIDATED'
        case 0:
          return 'UNVALIDATED'
        case 2:
          return 'REVALIDATED'
      }

      return ''
    },
    search() {
      this.meta.page = 1
      this.getCardTransactions()
    },
    exportReport() {
      let params = {
        category: this.category,
        start_date: this.getSubmitDate(),
        end_date: this.getSubmitDate('end')
      }
      this.loading_report_queue = true
      CostDataExport.create({
          parameters: params
        })
        .then(result => {
          this.loading_report_queue = false
          this.$toast.success(
            'The submit date requested has been queued for export. Check it on export tab'
          )
        })
        .catch(error => {
          this.$toast.error('An error occured while queueing for export')
          this.loading_report_queue = false
        })
    },
    getSubmitDate(option) {
      let year = option == 'end' ? this.form.year_end : this.form.year_start
      let month = option == 'end' ? this.form.month_end : this.form.month_start
      let date = year + '-' + (month + 1 < 10 ? '0' + (month + 1) : month + 1)

      return option == 'end' ?
        moment(date)
        .endOf('month')
        .format('YYYY-MM-DD') :
        moment(date)
        .startOf('month')
        .format('YYYY-MM-DD')
    },
    getReport() {
      let params = {
        start_date: this.getSubmitDate(),
        end_date: this.getSubmitDate('end'),
        category: this.category
      }

      this.loading_report_queue = true
      CardTransaction.generateReport({
          params: params
        })
        .then(result => {
          this.loading_report_queue = false
          this.$toast.success(
            'The submit date requested has been queued successfully. Check it on reports tab'
          )
        })
        .catch(error => {
          this.$toast.error('An error occured while queueing for report')
          this.loading_report_queue = false
          this.loading = false
        })
    },
    remove(bvModalEvt) {
      // bvModalEvt.preventDefault()
      // Category.delete(this.to_remove)
      //   .then(result => {
      //     this.$toast.success('Removed successfully')
      //     this.$nextTick(() => {
      //       this.dialogVisible = false
      //     })
      //     this.getCategories()
      //   })
      //   .catch(error => {
      //     this.$toast.error('An error occured while removing categry')
      //     console.error('error:', error.response.data)
      //   })
    },
    selectedName(selected) {
      return selected.id ? 'Edit ' + selected.name : 'New'
    },
    showRemove() {
      // this.to_remove = Object.assign({}, type)
      this.dialogVisible = true
    },
    showEdit(type) {
      this.selected = Object.assign({}, type)
      this.original = Object.assign({}, type)
    },
    getCardTransactions() {
      this.loading = true
      // this.form.start_date = this.form.year_start+'-'+( this.form.month_start+1 < 10 ? '0'+(this.form.month_start+1) : (this.form.month_start+1) )
      // this.form.end_date = this.form.year_end+'-'+( this.form.month_end+1 < 10 ? '0'+(this.form.month_end+1) : (this.form.month_end+1) )
      // this.form.end_date = moment(this.form.end_date).endOf('month').format('YYYY-MM-DD')

      let params = {
        page: this.meta.page,
        per_page: this.per_page,
        status: this.form.status,
        start_date: this.getSubmitDate(),
        end_date: this.getSubmitDate('end'),
        cost_difference: this.form.cost_difference,
        rate_difference: this.form.rate_difference,
        value: this.form.value,
        total_cost: this.form.total_difference,
        category: this.category,
        default_date: this.default_date,
        date_type: this.date_type
      }
      this.loading_report_queue = true
      CardTransaction.get({
          params: params
        })
        .then(result => {
          if (result.data.card_transactions)
            this.card_transactions = result.data.card_transactions
          this.meta = result.data.meta
          this.loading = false
          this.loading_report_queue = false
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching card transactions')
          console.error('error:', error.response.data)
          this.loading = false
          this.loading_report_queue = false
        })
    },
    validation(field) {
      switch (field) {
        case 'name':
          if (!this.selected.name) this.selected.name = ''
          return this.selected.name.length > 0
        default:
          return true
      }
    },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'name') return 'Name is required'
      return ''
    },
    onSubmit(evt) {
      // evt.preventDefault()
      // var action = this.selected.id ? Category.update(this.selected.id, this.selected) : Category.create(this.selected)
      // action.then(result => {
      //   this.$toast.success('Saved successfully')
      //   this.getCategories()
      // })
      // .catch(error => {
      //   this.$toast.error('An error occured while saving a category')
      //   console.error('error:', error.response.data)
      // })
    },
    onCancel(evt) {
      evt.preventDefault()
      this.reset()
    },
    reset() {
      this.original = null
      this.selected = null
    },
    create() {
      this.original = {
        name: null,
        email: null
      }
      this.selected = Object.assign({}, this.original)
    }
  },
  computed: {
    validateAll() {
      var self = this
      var response = true
      this.validate.forEach(function (field) {
        if (!self.validation(field)) response = false
      })

      return response
    },

  }
}
</script>

<style scoped>
.transaction-table {
  font-size: 12px !important;
}

.width-150 {
  width: 150px;
}

.width-200 {
  width: 200px;
}

.width-250 {
  width: 250px;
}

.difference-cell div {
  /* background: #fff !important; */
}
</style>
