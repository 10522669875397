<template>
  <div v-loading="loading">
    <el-form label-position="top" @submit.prevent="submit">
      <el-form-item label="Name *" prop="name" :error="v.form.name.$errors.length ? 'Name is required' : ''">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <div class="form-group">
        <label>Short Description *</label>
        <div :class="{ 'has-error-field': v.form.short_description.$errors.length }">
          <QuillEditor theme="snow" v-model:content="form.short_description" contentType="html" />
        </div>
        <div v-if="v.form.short_description.$errors.length" class="error-text">Description is required</div>
      </div>
      <div class="form-group">
        <label>Long Description</label>
        <div>
          <QuillEditor theme="snow" v-model:content="form.long_description" contentType="html" />
        </div>
      </div>
      <el-button native-type="submit" type="primary" plain>Save</el-button>
    </el-form>
  </div>
</template>
<script>

import { SupplierhubLot } from '@/api_services/supplierhub_lot'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import * as yup from 'yup';
import {
  useVuelidate
} from '@vuelidate/core'
import {
  required
} from '@vuelidate/validators'


export default {
  props: {
    lot: {
      type: Object,
      required: false
    },
    itt_framework_id: {
      type: Number,
      required: null
    },
    pin_notice_id: {
      type: Number,
      required: null
    },
    if_pin_notice: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      form: {
        name: null,
        short_description: null,
        long_description: null,
      },
      loading: false,
      min: 1,
    }
  },
  setup: () => ({
    v: useVuelidate()
  }),
  validations() {
    return {
      form: {
        short_description: {
          required,
          $autoDirty: true
        },
        name: {
          required,
          $autoDirty: true
        }
      }
    }
  },
  watch: {
    lot: function () {
      this.init()
    },
    'form.short_description': function () {
      if (this.form.short_description == '<p><br></p>') this.form.short_description = null
    },
  },
  created: function () {
    this.init()
  },
  methods: {
    init() {
      if (this.lot) this.form = Object.assign({}, this.lot)

      if (this.if_pin_notice) {
        this.form.if_pin_notice = this.if_pin_notice
        this.form.pin_notice_id = this.pin_notice_id
      }
      else {
        this.form.itt_framework_id = this.itt_framework_id
      }
    },
    validateRequire(value) {
      // if the field is empty
      if (!value) {
        return 'This field is required';
      }
      // All is good
      return true;
    },
    schema(){
      return yup.object({
        name: yup.string().min(this.min),
      });
    },
    async submit() {
      const result = await this.v.$validate()
      if (!result) return
      this.loading = true

      if (this.pin_notice_id) this.form.pin_notice_id = this.pin_notice_id
      if (this.itt_framework_id) this.form.itt_framework_id = this.itt_framework_id

      let request = this.form.id
        ? SupplierhubLot.update(this.form)
        : SupplierhubLot.create(this.form)

      request
        .then(result => {
          this.$toast.success('Lot name has been saved')
          this.$emit('close', this.form)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        }) 
    }
  }
}
</script>
<style></style>
