<template>
  <div style="text-align: left">
  
    <el-card style="max-width: 70rem" class="mx-auto" v-if="to_email_report > 0 && cost_data_report.generated_report">
      <b-form inline>
        <label class="sr-only" for="inline-form-input-username">Email to</label>
        <b-input-group prepend="Email to:" class="mb-2 mr-sm-2 mb-sm-0">
          <b-form-input id="inline-form-input-username" placeholder="" v-model="email"></b-form-input>
        </b-input-group>

        <b-button variant="primary" @click="sendReport()" :disabled="sending">{{ sending ? 'Sending':'Send'}}</b-button>
      </b-form> 
    </el-card>
    <el-card style="max-width: 70rem" class="mx-auto">
      <template #header >
        <span class="no-print">
          <!-- <b-button v-if="!cost_data_report.generated_report" variant="primary" @click="saveReport()" :disabled="sending">{{ sending ? 'Saving':'Save to storage to enable printing'}}</b-button> -->
          <!-- <b-button v-if="cost_data_report.generated_report" variant="primary" @click="print" style="float:right">Print</b-button> -->
          <b-button variant="link" :href="cost_data_report.report_pdf_link">Download Report</b-button>
        </span>
      </template> 
      <div class="card-body" id="print" style="padding: 0">
        <div v-if="cost_data_report.report_pdf_preview">
          
                   <!-- <iframe :src="cost_data_report.report_pdf_preview" style="width:100%; min-height:2000px;" frameborder="0" sandbox="allow-same-origin"></iframe> -->

         <iframe :src="cost_data_report.report_pdf_preview" style="width:100%; min-height:2000px;" frameborder="0" sandbox="allow-scripts allow-popups allow-same-origin"></iframe>
          <!-- <iframe :src="cost_data_report.absolute_path_for_generated_report" style="width:100%; height:900px;" frameborder="0"></iframe> -->
            <!-- <b-img :src="cost_data_report.absolute_path_for_generated_report" style="width:100%" alt="Responsive image" ></b-img> -->
        </div>
        <div id="chart-container" style="width: 100%"></div>
        <div id="email-container" style="width: 100%"></div>
        <div v-if="!printing" id="report-page" style="background-color: #fff;padding: 10px;display:none">
          <div v-if="!cost_data_report.absolute_path_for_generated_report">
            <div class="container" >
              <b-row>
                <b-col cols="6"> 
                    <img :src="logo" alt="Logo" height="120px">
                </b-col>
                <b-col cols="6">.
                  <h5 style="margin: 10px;text-align:center;font-size:30px !important">Month: {{ cost_data_report.submit_date }}</h5>
                  <h5 style="margin: 10px;text-align:center;font-size:30px !important">Client: {{ cost_data_report.company.name }}</h5>
                </b-col>  
              </b-row>
            </div>
            
            <hr>
            <h5>Savings Summary</h5>
            <table class="table table-striped small">  
              <tbody>
                <tr v-if="cost_data_report.result.revalidated && cost_data_report.result.revalidated.length">
                  <td>Reconciled Savings</td>
                  <td>{{ formattedAmount(cost_data_report.result.revalidated_total_savings) }}</td>
                </tr>
                <tr v-if="cost_data_report.result.revalidated && cost_data_report.result.revalidated.length">
                  <td>Current Month Savings</td>
                  <td style="text-align:right">{{ formattedAmount(cost_data_report.result.total_savings) }}</td>
                </tr>
                <tr>
                  <td>Total Savings</td>
                  <td style="text-align:right">{{ formattedAmount(cost_data_report.result.total_savings) }}</td>
                </tr>
                  <tr>
                  <td>Accumulated Savings since {{ cost_data_report.result.first_month_date }}</td>
                  <td style="text-align:right">{{ formattedAmount(cost_data_report.result.total_savings_from_start) }}</td>
                </tr>
                <tr style="background-color:#4dce2326">
                  <td>Accumulated Transactions since {{ cost_data_report.result.first_month_date }}</td>
                  <td style="text-align:right">{{ formattedAmount(cost_data_report.result.total_processed_transaction_amount_from_start) }}</td>
                </tr>
                <!-- <tr>
                  <td>CPRAS Fees</td>
                  <td>{{ formattedAmount(cost_data_report.result.cpras_fees) }}</td>
                </tr>
                <tr style="background-color:#4dce2326">
                  <td>NET Savings</td>
                  <td>{{ formattedAmount(cost_data_report.result.net_savings) }}</td>
                </tr> -->
              </tbody>
            </table>
            <hr>
            <h5 style="margin: 10px">Current Month Data</h5>
            <table class="table table-striped small">
              <thead>
                <th>Card Type</th>
                <th style="text-align:right">Value of Transactions</th>
                <th style="text-align:right">Prior Costs</th>
                <th style="text-align:right">Actual Costs</th>
                <th style="text-align:right">Savings</th>
              </thead>
              <tbody>
                <tr v-for="data in summary" :key="data.card_type">
                  <td>{{ data.card_type }}</td>
                  <td style="text-align:right">{{ formattedAmount(data.sum_processed_transaction_amount) }}</td>
                  <td style="text-align:right">
                    <span v-if="data.card_type != 'UNVALIDATED'">{{ formattedAmount(data.sum_old_charge) }}</span>
                  </td>
                  <td style="text-align:right">
                    <span v-if="data.card_type != 'UNVALIDATED'">{{ formattedAmount(data.sum_actual_cost) }}</span>
                  </td>
                  <td style="text-align:right">
                    <span v-if="data.card_type != 'UNVALIDATED'">{{ formattedAmount(data.savings) }}</span>
                  </td>
                </tr>
                  <tr style="background-color:#4dce2326">
                  <td>Total Result</td>
                  <td style="text-align:right">{{ formattedAmount(cost_data_report.result.sum_processed_transaction_amount) }}</td>
                  <td style="text-align:right">{{ formattedAmount(cost_data_report.result.sum_old_charge) }}</td>
                  <td style="text-align:right">{{ formattedAmount(cost_data_report.result.sum_actual_cost) }}</td>
                  <td style="text-align:right">{{ formattedAmount(cost_data_report.result.total_savings) }}</td>
                </tr>

              </tbody>
            </table>

            <h5 style="margin: 10px" v-if="cost_data_report.result.revalidated && cost_data_report.result.revalidated.length">Reconciled data from last month</h5>
            <table class="table table-striped small" v-if="cost_data_report.result.revalidated && cost_data_report.result.revalidated.length">
              <thead>
                <th>Card Type</th>
                <th style="text-align:right">Value of Transactions</th>
                <th style="text-align:right">Prior Costs</th>
                <th style="text-align:right">Actual Costs</th>
                <th style="text-align:right">Savings</th>
              </thead>
              <tbody>
                <tr v-for="data in cost_data_report.result.revalidated || []" :key="data.card_type">
                  <td>{{ data.card_type }}</td>
                  <td style="text-align:right">{{ formattedAmount(data.sum_processed_transaction_amount) }}</td>
                  <td style="text-align:right">
                    <span v-if="data.card_type != 'UNVALIDATED'">{{ formattedAmount(data.sum_old_charge) }}</span>
                  </td>
                  <td style="text-align:right">
                    <span v-if="data.card_type != 'UNVALIDATED'">{{ formattedAmount(data.sum_actual_cost) }}</span>
                  </td>
                  <td style="text-align:right">{{ formattedAmount(data.savings) }}</td>
                </tr>
                  <tr style="background-color:#4dce2326">
                  <td>Total Result</td>
                  <td style="text-align:right">{{ formattedAmount(cost_data_report.result.revalidated_total_sum_processed_transaction_amount) }}</td>
                  <td style="text-align:right">
                    <span>{{ formattedAmount(cost_data_report.result.revalidated_total_sum_old_charge) }}</span>
                  </td>
                  <td style="text-align:right">{{ formattedAmount(cost_data_report.result.revalidated_total_sum_actual_cost) }}</td>
                  <td style="text-align:right">{{ formattedAmount(cost_data_report.result.revalidated_total_savings) }}</td>
                </tr>
              </tbody>
            </table>
            <hr>
            <!-- <div>Number of Unvalidated transactions (to be reported in the next period) :  {{ total_unvalidated }}</div>
            <hr> -->
            <table class="table table-striped small">
              <thead>
                <th>Cost per £100 Processed</th>
                <th style="text-align:right">Prior Cost</th>
                <th style="text-align:right">Actual Cost</th>
                <th style="text-align:right">Saving</th>
              </thead>
              <tbody>
                <tr v-for="data in cost_per_processed" :key="data.name">
                  <td>{{ data.name }}</td>
                  <td style="text-align:right">{{ data.prior_cost ? data.prior_cost.toFixed(2): '0.00' }}</td>
                  <td style="text-align:right">{{ data.actual_cost ? data.actual_cost.toFixed(2): '0.00' }}</td>
                  <td style="text-align:right">{{ data.savings ? data.savings.toFixed(2): '0.00' }}</td>
                </tr>
              
              </tbody>
            </table>
            <hr>
            <table class="table table-striped small">
              <thead>
                <th>Cost per Average Transaction</th>
                <th style="text-align:right">Prior Cost</th>
                <th style="text-align:right">Actual Cost</th>
                <th style="text-align:right">Saving</th>
              </thead>
              <tbody>
                <tr v-for="data in cost_per_average" :key="data.card_type" >
                  <td>{{ data.card_type }}</td>
                  <td style="text-align:right">{{ data.prior_cost_over_trans ? data.prior_cost_over_trans.toFixed(2) : '0.00'  }}</td>
                  <td style="text-align:right">{{ data.actual_cost_over_trans ? data.actual_cost_over_trans.toFixed(2) : '0.00'}}</td>
                  <td style="text-align:right">{{ data.actual_cost_over_trans ? data.saving_per_transaction.toFixed(2) : '0.00' }}</td>
                </tr>
              
              </tbody>
            </table>

            <!-- <div v-if="loaded">
              <BarChart id="chart1" :chartData="generateChartDataForProcessedTransactionAmount(summary)"  />
              <hr>
              <BarChart id="chart2" :chartData="generateChartDataForPriorCost(summary)"  />
              <hr>
              <BarChart id="chart3" :chartData="generateChartDataForActualCost(summary)"  />
              <hr>
              <BarChart id="chart4" :chartData="generateBarChartDataFromSummary(summary)"  />
            </div> -->

          </div>
        </div>

        
      </div>
    </el-card>
  </div>
</template>

<script>
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image'
import CPRAS_logo from '@/assets/CPRAS_Main_Logo.png'
import acq from '@/assets/acq5.jpg'
// import BarChart from '@/components/BarChart'
import { CardTransaction } from '@/api_services/card_transaction'

import $ from 'jquery' // TODO: remove jquery
window.$ = window.jQuery = require('jquery') // TODO: remove jquery

export default {
  data() {
    return {
      mode: process.env.VUE_APP_MODE,
      email: '',
      logo: CPRAS_logo,
      acq5: acq,
      loaded: false,
      total_unvalidated: 0,
      printing: false,
      summary: [],
      summary_total: {},
      cost_per_processed: [],
      cost_per_average: [],
      formatter: new Intl.NumberFormat('en-US', {style: 'currency',currency: 'GBP',minimumFractionDigits: 2}),
      sending: false
    }
  },
  // components: { BarChart },
  props: ['data_report', 'to_email_report'],
  created: function() {
    this.cost_data_report = Object.assign({}, this.data_report);
    this.loaded = false
    this.getSummary()
    this.getCostPerProcessed()
  },
  watch: {
    to_email_report: function() {
      console.log(this.to_email_report)
    }
  },
  methods: {
    generateBarChart(title, labels, data) {
        return {
          labels: labels,
          datasets: [{
            label: title,
            barThickness: 30,
            maxBarThickness: 70,
            borderWidth: 1,
            backgroundColor: ['#ffadad','#9bf6ff','#caffbf', '#fdffb6', '#DA70D6', '#F08080', '#FF8C00', '#FAFAD2', '#98FB98', '#AFEEEE'],
            borderColor: ['rgba(255,99,132,1)','rgba(54, 162, 235, 1)','rgba(75, 192, 192, 1)','rgba(244, 208, 63, 1)'],
            pointBorderColor: '#2554FF',
            data: data
          }]
        }
    },
    generateChartDataForActualCost(collection) {
      let labels = []
      let savings = [];
      collection.forEach(data => {
        labels.push(data.card_type)
        savings.push(data.sum_actual_cost)
      });

      return this.generateBarChart('Cost Data Summary for Actual Cost', labels, savings)
    },
    generateChartDataForPriorCost(collection) {
      let labels = []
      let savings = [];
      collection.forEach(data => {
        labels.push(data.card_type)
        savings.push(data.sum_old_charge)
      });

      return this.generateBarChart('Cost Data Summary for Prior Cost', labels, savings)
    },
    generateChartDataForProcessedTransactionAmount(collection) {
      let labels = []
      let savings = [];
      collection.forEach(data => {
        labels.push(data.card_type)
        savings.push(data.sum_processed_transaction_amount)
      });

      return this.generateBarChart('Cost Data Summary for Processed Transaction Amount', labels, savings)
    },
    generateBarChartDataFromSummary(collection) {
      let labels = []
      let savings = [];
      collection.forEach(data => {
        labels.push(data.card_type)
        savings.push(data.savings)
      });

      return this.generateBarChart('Cost Data Summary of Savings', labels, savings)
    },
    getCostPerProcessed(){
        let self = this
        this.cost_per_processed = []
        this.cost_per_average = []
        this.summary_total = {
          sum_processed_transaction_amount: 0,
          sum_old_charge: 0,
          sum_total_cost: 0,
          sum_saving: 0
        }

        let over_all = {
            name: 'Overall',
            prior_cost: 0,
            actual_cost: 0,
            savings: 0,
            sum_processed_transaction_amount: 0,
            sum_old_charge: 0,
            sum_actual_cost: 0,
            sum_saving: 0,
            count: 0
          }

          let over_all_for_cost_per_average = {
            card_type: 'Overall',
            prior_percentage: 0,
            actual_percentage: 0,
            atv: 0,
            prior_cost_over_trans: 0,
            actual_cost_over_trans: 0,
            saving_per_transaction: 0
          }
        
          if(this.summary.length > 0){
            this.summary.forEach( function(data,index){
            let prior_cost =  (data.sum_old_charge / data.sum_processed_transaction_amount) * 100
            let actual_cost = (data.sum_actual_cost / data.sum_processed_transaction_amount) * 100
            let obj = {
              name: data.card_type,
              prior_cost: prior_cost,
              actual_cost: actual_cost,
              savings: prior_cost - actual_cost
            }

            self.summary[index].prior_percentage = (data.sum_old_charge / data.sum_processed_transaction_amount)
            self.summary[index].actual_percentage = (data.sum_actual_cost / data.sum_processed_transaction_amount)
            self.summary[index].atv = ( data.sum_processed_transaction_amount / data.count )
            self.summary[index].prior_cost_over_trans = self.summary[index].atv * self.summary[index].prior_percentage
            self.summary[index].actual_cost_over_trans = self.summary[index].atv * self.summary[index].actual_percentage
            self.summary[index].saving_per_transaction = self.summary[index].prior_cost_over_trans - self.summary[index].actual_cost_over_trans

            if (data.card_type !== 'UNVALIDATED') {
              over_all.sum_processed_transaction_amount += data.sum_processed_transaction_amount
              over_all.sum_old_charge += data.sum_old_charge
              over_all.sum_actual_cost += data.sum_actual_cost
              over_all.sum_saving += data.savings
              self.cost_per_processed.push(obj)
              self.cost_per_average.push(self.summary[index])
              over_all.count += data.count
            } else {
              self.total_unvalidated = data.count
            }

            self.summary_total.sum_processed_transaction_amount += data.sum_processed_transaction_amount
            self.summary_total.sum_old_charge += data.sum_old_charge
            self.summary_total.sum_total_cost += data.sum_actual_cost
            self.summary_total.sum_saving += data.savings
          })
          }

        over_all.prior_cost =  (over_all.sum_old_charge / over_all.sum_processed_transaction_amount) * 100
        over_all.actual_cost = (over_all.sum_actual_cost / over_all.sum_processed_transaction_amount) * 100
        over_all.savings = over_all.prior_cost - over_all.actual_cost


        over_all_for_cost_per_average.prior_percentage = (over_all.sum_old_charge / over_all.sum_processed_transaction_amount)
        over_all_for_cost_per_average.actual_percentage = (over_all.sum_actual_cost / over_all.sum_processed_transaction_amount)
        over_all_for_cost_per_average.atv = ( over_all.sum_processed_transaction_amount / over_all.count )
        over_all_for_cost_per_average.prior_cost_over_trans = over_all_for_cost_per_average.atv * over_all_for_cost_per_average.prior_percentage
        over_all_for_cost_per_average.actual_cost_over_trans = over_all_for_cost_per_average.atv * over_all_for_cost_per_average.actual_percentage
        over_all_for_cost_per_average.saving_per_transaction = over_all_for_cost_per_average.prior_cost_over_trans - over_all_for_cost_per_average.actual_cost_over_trans

        this.cost_per_processed.unshift(over_all);
        this.cost_per_average.unshift(over_all_for_cost_per_average);

        setTimeout(function(){ self.loaded = true; }, 1000);
        
    },
    formattedAmount(amount) {
      amount = this.formatter.format(amount).replace(/^(\D+)/, '$1 ')
      return amount
    },
    getSummary() {
      this.summary = this.cost_data_report.result.summary
    },
    print () {
      var self = this
      if (this.printing || this.cost_data_report.generated_report) {
        setTimeout(function(){
            self.$htmlToPaper('print', null, () => {
              console.log('Printing completed or was cancelled!')
              self.printing = false
              $( "#chart-container" ).empty()
            });
            }, 1000);
      } else {
        var node = document.getElementById('report-page')
        this.convertToImage(node)
      }
    },
    sendReport() {
      var element = document.getElementById('report-page')

      var chartContainer = document.getElementById('email-container')
      var self = this
      htmlToImage.toPng(element)
        .then(function (dataUrl) {
          var img = new Image();
          img.src = dataUrl;
          chartContainer.appendChild(img)
          var file = self.dataURLtoFile(dataUrl, 'cost_data_report_'+self.cost_data_report.id+'.png');
          self.sendReportViaRequest(file, true)

        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error)
        })
    },
    saveReport() {
      var element = document.getElementById('report-page')

      var chartContainer = document.getElementById('email-container')
      var self = this
      htmlToImage.toPng(element)
        .then(function (dataUrl) {
          var img = new Image();
          img.src = dataUrl;
          // chartContainer.appendChild(img)
          var file = self.dataURLtoFile(dataUrl, 'cost_data_report_'+self.cost_data_report.id+'.png');
          self.sendReportViaRequest(file)

        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error)
        })
    },
    sendReportViaRequest(file, send = false) {

        let formData = this.getFormData(file);
        this.sending = true

        if (send) {
            CardTransaction.emailCostDataReport(formData).then(result => {
              this.sending = false
              this.$toast.success('Email sent')
            })
            .catch(error => {
              this.sending = false
              this.$toast.error('An error occured while sending email')
              console.error('error:', error.response.data)
            })
        } else {
           CardTransaction.saveCostDataReport(formData).then(result => {
              this.sending = false
              this.$toast.success('Saved')
              this.$emit('save')
              this.cost_data_report = result.data.cost_data_report
            })
            .catch(error => {
              this.sending = false
              this.$toast.error('An error occured while sending email')
              console.error('error:', error.response.data)
            })
        }
        
    },
    getFormData(file) {
        var formData = new FormData();
        if (this.cost_data_report.id) formData.append("id", this.cost_data_report.id);
        formData.append("file", file);
        formData.append("email", this.email);
        return formData;
      },
    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    },
    loadXHR(url) {
        return new Promise(function(resolve, reject) {
            try {
                var xhr = new XMLHttpRequest();
                xhr.open("GET", url);
                xhr.responseType = "blob";
                xhr.onerror = function() {reject("Network error.")};
                xhr.onload = function() {
                    if (xhr.status === 200) {resolve(xhr.response)}
                    else {reject("Loading error:" + xhr.statusText)}
                };
                xhr.send();
            }
            catch(err) {reject(err.message)}
        });
    },
    convertToImage(element) {
      var chartContainer = document.getElementById('chart-container')
      var self = this
      htmlToImage.toPng(element, {height: (element.clientHeight*1.1)})
      // htmlToImage.toPng(element)
        .then(function (dataUrl) {
          var img = new Image();
          img.src = dataUrl;
          // if (self.cost_data_report.generated_report) img.src = self.cost_data_report.absolute_path_for_generated_report;
          chartContainer.appendChild(img)
          self.printing = true
          self.print()
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error)
        })
    },
  }
}
</script>