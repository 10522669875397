<template>
  <div style="text-align: left">
    <el-card style="max-width: 70rem" class="mx-auto">
      <template #header >
        <span class="no-print">
          <b-button variant="danger" @click="goBack()">Back</b-button>
          <b-button variant="primary" @click="print" style="float:right">Print</b-button>
        </span>
      </template> 
      <div class="no-print">
          <b-form-group  label="Contract Period (in Years)" label-for="year_selection">
            <b-form-radio-group id="year_selection" v-model="year">
              <b-form-radio v-for="(item, index) in years" :key="index" :value="item" >{{ item }} years</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <div v-if="cct_category == 'banking'">
            <div v-if="year > 3">
              <b-row>
                <b-col cols="4"><span>Would you like to add a discretionary extension period? </span></b-col>
                <b-col cols="2">
                  <b-form-select v-model="status" :options="options"></b-form-select>
                </b-col>
              </b-row>
                
              <br>
              <div v-if="status">
                <div>
                  <b-form-group  label="How many years?" label-for="add_on_year_select">
                    <b-form-radio-group id="add_on_year_select" v-model="add_on_year">
                      <b-form-radio v-for="(item, index) in add_on_years" :key="index" :value="item" >{{ item }} years</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </div> 
              </div>
            </div>
          </div>
          
        </div>
      <div class="card-body" id="print">       
        <div id="chart-container" style="width: 100%"></div>
        <div v-if="bank_transactions">
          <div v-if="!printing" id="report-page">
            <div class="container">
              <img :src="logo" class="brand_logo" alt="Logo" height="150px">
            </div>
            <div v-if="bank_transactions.length>1">
              <span style="text-align: center"><h3>Cost Comparison Report</h3></span><br>
              <table class="table table-striped small">
                <thead> 
                  <th></th>
                  <th v-for="(supplier_name,index) in supplier_names" :key="index">{{ supplier_name }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>Total Charges</td>
                    <td v-for="(bank_transaction, index) in bank_transactions" :key="index">£ {{ multiplyByYear(bank_transaction.total_charge).toLocaleString('en-US',{minimumFractionDigits: 2}) }}</td>
                  </tr>
                  <tr>
                    <td>Savings</td>
                    <td v-for="(bank_transaction, index) in bank_transactions" :key="index"> £ {{ multiplyByYear(bank_transaction.savings).toLocaleString('en-US',{minimumFractionDigits: 2}) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br>
            <div v-for="(bank_transaction,index) in bank_transactions" :key="index">

              <b-row>
                <!-- <b-col><span><h4>{{ supplier_names[index] }} - Cost Comparison Report <b-form-input v-model="company_name" size="lg" style="font-weight: bold; border: 0; box-shadow: none" placeholder="Enter Company Name"></b-form-input></h4></span></b-col> -->
                <b-col><span><h4>{{ (cct_category == 'banking') ? "" : supplier_names[index] }} Cost Comparison Report <b-form-input v-model="company_name" size="lg" style="font-weight: bold; border: 0; box-shadow: none" placeholder="Enter Company Name"></b-form-input></h4></span></b-col>
              </b-row>      
              
              <div v-if="cct_category !== 'banking'">
                <span>Type: {{ (bank_transaction.annualisation_type == 0) ? "Annual" : "Monthly" }}</span><br>
                <!-- <span v-if="bank_transaction.annualisation_type == 1">Typical Month: {{ bank_transaction.typical_month ? "Yes" : "No" }}<br></span> -->
                <span>Cost at current rate: £ {{ bank_transaction.annualized_current_charge.toLocaleString('en-US',{minimumFractionDigits: 2}) }}</span><br>  
              </div>
              

              <!-- Banking Start  -->
              <div v-if="cct_category == 'banking'">
                <div>
                  <div>
                    <h5><strong>I. Savings Summary Table</strong></h5>
                    <table class="table table-striped small">
                      <thead class="table-primary font_bold">
                        <th>Summary Details</th>
                        <th>Year 1</th>
                        <th v-if="year == 10 && bank_transaction.annualized_current_charge > 40000"> Year 2</th>
                        <th v-if="year > 1">Initial Contract term({{ year }} Years)</th>
                        <th v-if="status">{{ add_on_year }} years extension period</th>
                        <th>Total</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Current cost</td>
                          <td>£ {{ bank_transaction.annualized_current_charge.toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) }}</td>
                          <td>£ {{ multiplyByYear(bank_transaction.annualized_current_charge).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) }}</td>
                          <td v-if="status">£ {{ multiplyAddOnByYear(bank_transaction.annualized_current_charge).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) }}</td>
                          <td>£ {{ (status) ? (multiplyByYear(bank_transaction.annualized_current_charge) + multiplyAddOnByYear(bank_transaction.annualized_current_charge)).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2})  : multiplyByYear(bank_transaction.annualized_current_charge).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) }}</td>
                        </tr>
                        <tr>
                          <td>Lloyds bank new cost</td>
                          <td>£ {{ (bank_transaction.total_charge - (fbpsavings) == 0 || 0 > bank_transaction.total_charge - (fbpsavings)) ? "--" : (bank_transaction.total_charge - (fbpsavings)).toLocaleString('en-US',{maximumFractionDigits: 2}) }}</td>
                          <td v-if="year == 10 && bank_transaction.annualized_current_charge > 40000">£ {{ (bank_transaction.total_charge - (fbp_extra_savings)).toLocaleString('en-US',{maximumFractionDigits: 2})  }}</td>
                          <td v-if="year > 1">£ {{ (multiplyByYear(bank_transaction.total_charge) - (fbpsavings + fbp_extra_savings)).toLocaleString('en-US',{maximumFractionDigits: 2}) }}</td>
                          <td v-if="status">£ {{ (multiplyAddOnByYear(bank_transaction.total_charge) - fbp_add_on_savings).toLocaleString('en-US',{maximumFractionDigits: 2}) }}</td>
                          <td>£ {{ (status) ? ((multiplyByYear(bank_transaction.total_charge) - (fbpsavings + fbp_extra_savings))  + (multiplyAddOnByYear(bank_transaction.total_charge) - fbp_add_on_savings)).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) : (multiplyByYear(bank_transaction.total_charge) - (fbpsavings + fbp_extra_savings)).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) }}</td>
                        </tr>
                        <tr>
                          <td>Gross Savings</td>
                          <td>£ {{ gross_savings.toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) }}</td>
                          <td v-if="year == 10 && bank_transaction.annualized_current_charge > 40000">£ {{ getGrossSavingExtra(bank_transaction.annualized_current_charge, bank_transaction.total_charge, rem_fbp, fbp_extra_savings).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2})  }}</td>
                          <td v-if="year > 1">£ {{ (year == 10 && bank_transaction.annualized_current_charge > 40000) ? ((multiplyByYear(gross_regular_savings) - (2 * gross_regular_savings)) + gross_savings + gross_savings_extra).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) : ((multiplyByYear(gross_regular_savings) - gross_regular_savings) + gross_savings).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2})  }}</td>
                          <td v-if="status">£ {{((multiplyAddOnByYear(gross_regular_savings) - gross_regular_savings) + add_on_gross_savings).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2})  }}</td>
                          <td v-if="status"> £ {{ (year == 10 && bank_transaction.annualized_current_charge > 40000) ? (((multiplyByYear(gross_regular_savings) - (2 * gross_regular_savings)) + gross_savings + gross_savings_extra) +  ((multiplyAddOnByYear(gross_regular_savings) - gross_regular_savings) + add_on_gross_savings)).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) : (((multiplyByYear(gross_regular_savings) - gross_regular_savings) + gross_savings) +  ((multiplyAddOnByYear(gross_regular_savings) - gross_regular_savings) + add_on_gross_savings)).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2})  }}</td>
                          <td v-else>£ {{ (year == 10 && bank_transaction.annualized_current_charge > 40000) ? ((multiplyByYear(gross_regular_savings) - (2 * gross_regular_savings)) + gross_savings + gross_savings_extra).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) : ((multiplyByYear(gross_regular_savings) - gross_regular_savings) + gross_savings).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) }}</td>
                        </tr>
                        <tr>
                          <td>BFF charges</td>
                          <td>£ {{ (gross_savings * ((0>gross_savings) ? 0 : psf_charge)).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) }}</td>
                          <td v-if="year == 10 && bank_transaction.annualized_current_charge > 40000">£ {{ ((gross_savings_extra * psf_charge) > 0) ?  (gross_savings_extra * psf_charge).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) : "0.0000"}}</td>
                          <td v-if="year > 1">£ {{ (year == 10 && bank_transaction.annualized_current_charge > 40000) ? ((multiplyByYear((gross_regular_savings * ((0>gross_regular_savings) ? 0 : psf_charge))) - (2 * (gross_regular_savings * ((0>gross_regular_savings) ? 0 : psf_charge)))) + (gross_savings * ((0>gross_savings) ? 0 : psf_charge)) + (gross_savings_extra * ((0>gross_savings_extra) ? 0 : psf_charge))).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) : ((multiplyByYear(gross_regular_savings * ((0>gross_regular_savings) ? 0 : psf_charge)) - (gross_regular_savings* ((0>gross_regular_savings) ? 0 : psf_charge))) + (gross_savings* ((0>gross_savings) ? 0 : psf_charge))).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2})  }}</td>
                          <td v-if="status">£ {{((multiplyAddOnByYear((gross_regular_savings* ((0>gross_regular_savings) ? 0 : psf_charge))) - (gross_regular_savings* ((0>gross_regular_savings) ? 0 : psf_charge))) + (add_on_gross_savings* ((0>add_on_gross_savings) ? 0 : psf_charge))).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2})  }}</td>
                          <td v-if="status"> £ {{ (year == 10 && bank_transaction.annualized_current_charge > 40000) ? (((multiplyByYear((gross_regular_savings* ((0>gross_regular_savings) ? 0 : psf_charge))) - (2 * (gross_regular_savings* ((0>gross_regular_savings) ? 0 : psf_charge)))) + (gross_savings* ((0>gross_savings) ? 0 : psf_charge)) + (gross_savings_extra* ((0>gross_savings_extra) ? 0 : psf_charge))) +  ((multiplyAddOnByYear((gross_regular_savings* ((0>gross_regular_savings) ? 0 : psf_charge))) - (gross_regular_savings* ((0>gross_regular_savings) ? 0 : psf_charge))) + (add_on_gross_savings* ((0>add_on_gross_savings) ? 0 : psf_charge)))).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) : (((multiplyByYear((gross_regular_savings* ((0>gross_regular_savings) ? 0 : psf_charge))) - (gross_regular_savings* ((0>gross_regular_savings) ? 0 : psf_charge))) + (gross_savings* ((0>gross_savings) ? 0 : psf_charge))) +  ((multiplyAddOnByYear((gross_regular_savings* ((0>gross_regular_savings) ? 0 : psf_charge))) - (gross_regular_savings* ((0>gross_regular_savings) ? 0 : psf_charge))) + (add_on_gross_savings* ((0>add_on_gross_savings) ? 0 : psf_charge)))).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2})  }}</td>
                          <td v-else>£ {{ (year == 10 && bank_transaction.annualized_current_charge > 40000) ? ((multiplyByYear((gross_regular_savings* ((0>gross_regular_savings) ? 0 : psf_charge))) - (2 * (gross_regular_savings* ((0>gross_regular_savings) ? 0 : psf_charge)))) + (gross_savings* ((0>gross_savings) ? 0 : psf_charge)) + (gross_savings_extra* ((0>gross_savings_extra) ? 0 : psf_charge))).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) : ((multiplyByYear((gross_regular_savings* ((0>gross_regular_savings) ? 0 : psf_charge))) - (gross_regular_savings* ((0>gross_regular_savings) ? 0 : psf_charge))) + (gross_savings* ((0>gross_savings) ? 0 : psf_charge))).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) }}</td>
                        </tr>
                        <tr class="font_bold table-info">
                          <td >Net Savings</td>
                          <td >£ {{ (getNetSavings(gross_savings, ((0>gross_savings) ? 0 : psf_charge))).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) }}</td> 
                          <td  v-if="year == 10 && bank_transaction.annualized_current_charge > 40000">£ {{  (gross_savings_extra -  (gross_savings_extra * ((0>gross_savings_extra) ? 0 : psf_charge))).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) }}</td>
                          <td  v-if="year > 1">£ {{ (year == 10 && bank_transaction.annualized_current_charge > 40000) ? ((multiplyByYear((gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge)))) - (2 * (gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge))))) + ((gross_savings-(gross_savings*((0>gross_savings)? 0 : psf_charge))) +  (gross_savings_extra-(gross_savings_extra*((0>gross_savings_extra)? 0 : psf_charge))))).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) : ((multiplyByYear((gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge)))) - (gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge)))) + (gross_savings-(gross_savings*((0>gross_savings)? 0 : psf_charge)))).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2})  }}</td>
                          <td  v-if="status">£ {{ ((multiplyAddOnByYear((gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge)))) - (gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge)))) +  (add_on_gross_savings-(add_on_gross_savings*((0>add_on_gross_savings)? 0 : psf_charge)))).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2})  }}</td>
                          <td  v-if="status">£ {{ (year == 10 && bank_transaction.annualized_current_charge > 40000) ? (((multiplyByYear((gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge)))) - (2 * (gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge))))) + ((gross_savings-(gross_savings*((0>gross_savings)? 0 : psf_charge))) + (gross_savings_extra-(gross_savings_extra*((0>gross_savings_extra)? 0 : psf_charge))))) + (((multiplyAddOnByYear((gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge)))) - (gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge)))) +  (add_on_gross_savings-(add_on_gross_savings*((0>add_on_gross_savings)? 0 : psf_charge))))) ).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) : (((multiplyByYear((gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge)))) - (gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge)))) + net_savings) + ((multiplyAddOnByYear((gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge)))) - (gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge)))) +  (add_on_gross_savings-(add_on_gross_savings*((0>add_on_gross_savings)? 0 : psf_charge))))).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) }}</td>
                          <td  v-else>£ {{ (year == 10 && bank_transaction.annualized_current_charge > 40000) ? ((multiplyByYear((gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge)))) - (2 * (gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge))))) + (gross_savings-(gross_savings*((0>gross_savings)? 0 : psf_charge))) +  (gross_savings_extra-(gross_savings_extra*((0>gross_savings_extra)? 0 : psf_charge)))).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) : ((multiplyByYear((gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge)))) - (gross_regular_savings-(gross_regular_savings*((0>gross_regular_savings)? 0 : psf_charge)))) + net_savings).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <br>
                  </div>

                  <h5><strong>II. Cost/Saving Summary(including free banking period)</strong></h5>
                  <table class="table table-striped small">
                    <thead class="table-primary font_bold">
                      <th>Contract Details</th>
                      <th></th>
                      <td v-if="year == 10 && bank_transaction.annualized_current_charge > 40000">Year 2</td>
                      <th v-if="status">Extension period</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Contract</td>
                        <td>{{ year }} {{ year > 1  ? "years" : "year"  }}</td>
                        <td v-if="year == 10 && bank_transaction.annualized_current_charge > 40000"></td>
                        <td v-if="status">{{ add_on_year }} years</td>
                      </tr>
                      <tr>
                        <td>Free Banking Period</td>
                        <td>{{ ((getFreeBankingPeriod(year, bank_transaction.annualized_current_charge) - rem_fbp) == 0) ? "N/A" : (getFreeBankingPeriod(year, bank_transaction.annualized_current_charge) - rem_fbp) + " months" }}</td>
                        <td v-if="year == 10 && bank_transaction.annualized_current_charge > 40000">{{ rem_fbp + " months" }}</td>
                        <td v-if="status">{{ (getFBPaddOn(add_on_year, bank_transaction.annualized_current_charge) == 0) ? "N/A" : getFBPaddOn(add_on_year, bank_transaction.annualized_current_charge) + " months" }}</td>
                      </tr>
                      <tr class="hidden">
                        <td>Free Banking Period Savings</td>
                        <td>{{ (getFBPSaving(bank_transaction.total_charge, 12) == 0) ? "N/A" : "£ " + getFBPSaving(bank_transaction.total_charge, 12).toFixed(2) }}</td>
                        <!-- <td class="hidden" v-if="year == 10 && bank_transaction.annualized_current_charge > 40000">{{ getFBPmaxSaving(bank_transaction.total_charge) }}</td> -->
                        <td v-if="year == 10 && bank_transaction.annualized_current_charge > 40000">£ {{ fbp_extra_savings.toFixed(2) }}</td>
                        <td v-if="status">{{ (getFBAddonSaving(bank_transaction.total_charge, 12) == 0) ? "N/A" : "£ " + getFBAddonSaving(bank_transaction.total_charge, 12).toFixed(2) }}</td>
                      </tr>
                      <tr class="hidden">
                        <td>Gross Savings (in First Year)</td>
                        <td>£ {{ getGrossSaving(bank_transaction.annualized_current_charge, bank_transaction.total_charge, freebankingperiod, fbpsavings).toFixed(2)  }}</td>
                        <td v-if="year == 10 && bank_transaction.annualized_current_charge > 40000">£ {{ getGrossSavingExtra(bank_transaction.annualized_current_charge, bank_transaction.total_charge, rem_fbp, fbp_extra_savings).toFixed(2)  }}</td>
                        <td v-if="status">£ {{ getAddOnGrossSaving(bank_transaction.annualized_current_charge, bank_transaction.total_charge, fbpadd_on_period, fbp_add_on_savings).toFixed(2)  }}</td>
                      </tr>
                      <tr class="hidden">
                        <td>Framework charge (in First Year)</td>
                        <td>£ {{ ((gross_savings * psf_charge) > 0) ? (gross_savings * psf_charge).toFixed(2) : "0.0000" }}</td>
                        <td v-if="year == 10 && bank_transaction.annualized_current_charge > 40000">£ {{ ((gross_savings_extra * psf_charge) > 0) ?  (gross_savings_extra * psf_charge).toFixed(2) : "0.0000"}}</td>
                        <td v-if="status">£ {{ ((add_on_gross_savings * psf_charge) > 0) ? (add_on_gross_savings * psf_charge).toFixed(2) : "0.0000" }}</td>
                      </tr>
                      <tr class="hidden">
                        <td>Net Savings (in First Year)</td>
                        <td>£ {{ (gross_savings -  (gross_savings * psf_charge)).toFixed(2) }}</td>
                        <td v-if="year == 10 && bank_transaction.annualized_current_charge > 40000">£ {{  (gross_savings_extra -  (gross_savings_extra * psf_charge)).toFixed(2) }}</td>
                        <td v-if="status">£ {{ (add_on_gross_savings -  (add_on_gross_savings * 0.2)).toFixed(2) }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <br>
                  <!-- Matrix table start -->


                  <div v-if="bank_transaction.annualized_current_charge<20000 ">
                    <span><b>The length of free banking periods available depends on the contract term selected, the table below shows the different free banking period that are available</b></span>
                    <table class="table table-striped">
                      <th></th>
                      <th>4 years</th>
                      <th>5 years</th>
                      <th>6 years</th>
                      <th>7 years</th>
                      <th>8 years</th>
                      <th>9 years</th>
                      <th>10 years</th>
                      <tbody>
                        <tr>
                          <td>free banking</td>
                          <td>3 months</td>
                          <td>6 months</td>
                          <td>6 months</td>
                          <td>6 months</td>
                          <td>6 months</td>
                          <td>6 months</td>
                          <td>6 months</td>
                        </tr>
                      </tbody>
                    </table>
                    <br>
                  </div>

                  <div v-if="bank_transaction.annualized_current_charge > 20000 && bank_transaction.annualized_current_charge < 40000">
                    <span><b>The length of free banking periods available depends on the contract term selected, the table below shows the different free banking period that are available</b></span>
                    <table class="table table-striped">
                      <th></th>
                      <th>4 years</th>
                      <th>5 years</th>
                      <th>6 years</th>
                      <th>7 years</th>
                      <th>8 years</th>
                      <th>9 years</th>
                      <th>10 years</th>
                      <tbody>
                        <tr>
                          <td>free banking</td>
                          <td>6 months</td>
                          <td>6 months</td>
                          <td>9 months</td>
                          <td>12 months</td>
                          <td>12 months</td>
                          <td>12 months</td>
                          <td>12 months</td>
                        </tr>
                      </tbody>
                    </table>
                    <br>
                  </div>

                  <div v-if="bank_transaction.annualized_current_charge > 40000">
                    <span><b> length of free banking periods available depends on the contract term selected, the table below shows the different free banking period that are available </b></span>
                    <table class="table table-striped">
                      <th></th>
                      <th>4 years</th>
                      <th>5 years</th>
                      <th>6 years</th>
                      <th>7 years</th>
                      <th>8 years</th>
                      <th>9 years</th>
                      <th>10 years</th>
                      <tbody>
                        <tr>
                          <td>free banking</td>
                          <td>6 months</td>
                          <td>9 months</td>
                          <td>9 months</td>
                          <td>12 months</td>
                          <td>12 months</td>
                          <td>12 months</td>
                          <td>18 months</td>
                        </tr>
                      </tbody>
                    </table>
                    <br>
                  </div>
                    

                  <!-- Matrix table end -->
                  
                  <div>
                    <h5><strong>III. Yearly Cost/Savings breakdown</strong></h5>
                    <table class="table table-striped small">
                      <thead class="table-primary font_bold">
                        <th></th>
                        <th>Cost using existing service</th>
                        <th>Proposed BFF charges</th>
                        <!-- <th>Framework charge</th> -->
                        <th>Net Cost/Savings</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Year 1</td>
                          <td>£ {{ bank_transaction.annualized_current_charge.toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) }}</td>
                          <td>£ {{ ((bank_transaction.total_charge - fbpsavings) == 0 || 0 > (bank_transaction.total_charge - fbpsavings) ) ? "--" : (bank_transaction.total_charge - fbpsavings).toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) }}</td>
                          <!-- <td>£ {{ (gross_savings * psf_charge).toFixed(2) }}</td> -->
                          <td class="font_bold">£ {{ (0>gross_savings) ? gross_savings.toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) : net_savings.toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) }}</td>

                        </tr>
                        <tr v-for="item in (year-1)" :key="item.id">
                            <td>Year {{ item+1 }}</td>
                            <td>£ {{ bank_transaction.annualized_current_charge.toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) }}</td>
                            <td v-if="(item + 1) == 2">£ {{ (year == 10 && bank_transaction.annualized_current_charge > 40000) ? (bank_transaction.total_charge - fbp_extra_savings).toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) : (bank_transaction.total_charge).toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) }}</td>
                            <td v-else>£ {{ (bank_transaction.total_charge).toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) }}</td>
                            <td class="font_bold" v-if="(item + 1) == 2">£ {{ (year == 10 && bank_transaction.annualized_current_charge > 40000) ? (gross_savings_extra - (gross_savings_extra * ((0>gross_savings_extra) ? 0 : psf_charge))).toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) : ((gross_regular_savings) - (gross_regular_savings * ((0>gross_regular_savings) ? 0 : psf_charge))).toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) }}</td>
                            <td class="font_bold" v-else>£ {{ (gross_regular_savings - (gross_regular_savings * ((0>gross_regular_savings) ? 0 : psf_charge))).toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) }}</td>
                        </tr>
                        
                        <tr v-if="status && add_on_year > 0">
                          <td>Year {{ year + 1 }}</td>
                          <td>£ {{ bank_transaction.annualized_current_charge.toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) }}</td>
                          <td>£ {{ (bank_transaction.total_charge - fbp_add_on_savings).toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) }}</td>
                          <td class="font_bold">£ {{ (add_on_gross_savings - (add_on_gross_savings * ((0>add_on_gross_savings) ? 0 : psf_charge))).toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) }}</td>
                        </tr>

                        <tr v-if="!status || add_on_year==0">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr v-else v-for="item in (add_on_year-1)" :key="item.id" >
                          <td>Year {{ (item+1) + year }}</td>
                          <td>£ {{ bank_transaction.annualized_current_charge.toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) }}</td>
                          <td>£ {{ (bank_transaction.total_charge).toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) }}</td>
                          <td class="font_bold">£ {{ (gross_regular_savings - (gross_regular_savings * ((0>gross_regular_savings) ? 0 : psf_charge))).toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits:2}) }}</td>
                        </tr>


                      </tbody>
                    </table>
                    <br>
                  </div>
                  

                  

                </div>
              </div>   

              <!-- Banking End -->

              <div v-else>
                <table class="table table-striped small">
                  <thead>
                    <th></th>
                    <th>1 Year</th>
                    <th v-if="year > 1">{{ year }} Years</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Total Charges</td>
                      <td>£ {{ bank_transaction.total_charge.toFixed(2) }}</td>
                      <td v-if="year > 1">£ {{ multiplyByYear((bank_transaction.total_charge).toFixed(2)) }}</td>
                    </tr>
                    <tr>
                      <td>Gross Savings</td>
                      <td>£ {{ bank_transaction.savings.toFixed(2) }}</td>
                      <td v-if="year > 1">£ {{ multiplyByYear(bank_transaction.savings.toFixed(2) ) }}</td>
                    </tr>
                    <tr>
                      <td>Net Savings</td>
                      <td>£ {{ (bank_transaction.savings - (bank_transaction.savings*psf_charge)).toFixed(2) }}</td>
                      <td v-if="year > 1">£ {{ multiplyByYear((bank_transaction.savings - (bank_transaction.savings*psf_charge)).toFixed(2) ) }}</td>
                    </tr>
                  </tbody>
                </table>
                <br>
              </div>


                           
              
              
              <BarChart :chartData="dataCollections[index]"  />

              <div> 
                <h5><strong>IV. New Cost breakdown table</strong></h5>
                <span>Annual Transactions:</span>
                <table class="table table-striped small">
                  <thead>
                    <tr>
                      <!-- <th>id</th> -->
                      <th>service</th>
                      <th>rate(%)</th>
                      <!-- <th v-if="bank_transaction.annualisation_type == 1 && !bank_transaction.typical_month && bank_transaction.annualisation_category == 0">Ave Monthly</th> -->
                      <!-- <th v-if="bank_transaction.annualisation_type == 1 && !bank_transaction.typical_month && bank_transaction.annualisation_category == 1">Annual</th> -->
                      <!-- <th v-if="bank_transaction.annualisation_type == 1 && !bank_transaction.typical_month">Monthly</th> -->
                      <!-- <th v-else>amount/volume</th> -->
                      <th>ppt(pence per transaction)</th>
                      <th>amount/volume</th>
                      <th>proposed cost</th>
                    </tr>
                  </thead>
                
                  <tbody>
                    <tr v-for="(transaction,index) in bank_transaction.transactions" :key="index">
                      <!-- <td>{{ transaction.id }}</td>  -->
                      <td>{{ transaction.transaction_type.name }}</td>  
                      <td>{{ transaction.rate.toFixed(4) }} %</td>  
                      <td>{{ transaction.ppt_amount.toFixed(4) }}</td>
                      <!-- <td v-if="bank_transaction.annualisation_type == 1 && !bank_transaction.typical_month && bank_transaction.annualisation_category == 0">{{ transaction.average_monthly_cost.toFixed(2) }}</td>
                      <td v-if="bank_transaction.annualisation_type == 1 && !bank_transaction.typical_month && bank_transaction.annualisation_category == 1">{{ transaction.actual_annual_cost.toFixed(2) }}</td>
                      <td v-if="bank_transaction.annualisation_type == 1 && !bank_transaction.typical_month">{{ transaction.monthly_cost.toFixed(2) }}</td>
                      <td v-else> {{ transaction.amount.toFixed(2) }}</td>   -->
                      <td> {{ transaction.amount.toLocaleString('en-US') }}</td>
                      <td> £ {{ transaction.total.toLocaleString('en-US',{minimumFractionDigits: 2}) }}</td>  
                    </tr>
                  </tbody>
                </table>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import $ from 'jquery'
import { TransactionType } from '../api_services/transaction_type'
import { Supplier } from '../api_services/suppliers'
import { BankTransaction } from '../api_services/bank_transaction'
import BarChart from '@/components/BarChart'
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image'
import CPRAS_logo from '@/assets/CPRAS_Main_Logo.png'

export default {
  components: {
    BarChart,
  },
  watch: {
    max_year: function() {
      this.updateBanTransactions()
    },
    status: function() {
      this.updateBanTransactions()
    },
    year: function() {
      this.updateBanTransactions()
    },
    add_on_year: function() {
      this.updateBanTransactions()
    },
    gross_savings: function() {
      this.updateBanTransactions()
    },
    add_on_gross_savings: function() {
      this.updateBanTransactions()
    },
    net_savings: function(){
      this.updateBanTransactions()
    },
    add_on_net_savings: function() {
      this.updateBanTransactions()
    },
    fbpsavings: function() {
      this.updateBanTransactions()
    },
    fbpaddonsaving: function() {
      this.updateBanTransactions()
    },
    fbp_add_on_savings: function() {
      this.updateBanTransactions()
    },
    fbp_extra_savings: function() {
      this.updateBanTransactions()
    },
    gross_savings_extra: function() {
      this.updateBanTransactions()
    },
    net_extra_savings: function() {
      this.updateBanTransactions()
    },
    gross_regular_savings: function() {
      this.updateBanTransactions()
    },
    net_regular_savings: function() {
      this.updateBanTransactions()
    },
    company_name: function() {
      this.updateBanTransactions()
    },
    max_gross_savings: function() {
      this.updateBanTransactions()
    },
    fbp_max_savings: function() {
      this.updateBanTransactions()
    },
    fbp_max_extra_savings: function() {
      this.updateBanTransactions()
    }
  },
  data() {
    return {
      max_year: 10,
      company_name: "",
      logo: CPRAS_logo,
      year: 4,
      add_on_years: [2,3,4,5],
      add_on_year: 0,
      options: [{value:true, text: "yes"}, {value: false, text: "no"}],
      years: [1,2,3,4,5,6,7,8,9,10],
      dataCollections: [],
      bank_transactions: [],
      transactions: [],
      printing: false,
      cct_category: "",
      supplier_names: [],
      monthly_charge: 0,
      freebankingperiod: 0,
      fbpsavings: 0,
      fbp_add_on_savings: 0,
      add_on_gross_savings: 0,
      gross_savings: 0,
      net_savings: 0,
      psf_charge: 0.2,
      add_on_net_savings: 0,
      fbpadd_on_period: 0,
      status: false,
      rem_fbp: 0,
      fbp_extra_savings: 0,
      gross_savings_extra: 0,
      gross_regular_savings: 0,
      net_regular_savings: 0,
      net_extra_savings: 0,
      gross_add_on_regular_savings: 0,
      max_gross_savings: 0,
      max_fbperiod: 18,
      fbp_max_savings: 0,
      fbp_max_extra_savings: 0,
      group_code:""
    }
  },
  props: {
    group_code: {
      required: true
    } 
  },
  created (props) {
    if (this.$route.params.group_code) {
      this.group_code = this.$route.params.group_code
        this.getBankTransactionById(this.group_code)
    } else {
      this.$toast.error('Unable to find the group code')
    }
    //console.log(this.$route.params.group_code)
  },
  methods: {
    clearAddOn() {
      if (!this.status) {
        this.add_on_year = 0,
        this.add_on_gross_savings = 0
        this.add_on_net_savings = 0
        this.fbp_add_on_savings = 0
      }
    },
    multiplyByMaxYear(number) {
      return (this.max_year * number)
    },
    multiplyByYear(number) {
      return (this.year * number)
    },
    multiplyAddOnByYear(number) {
      return (this.add_on_year * number)
    },
    updateBanTransactions() {
      var self = this;
      this.clearAddOn()
      this.dataCollections = []
      this.bank_transactions.forEach(function(bank_transaction){
          self.fillData(bank_transaction)
      })
    },
    getCategoryString(category) {
      switch (category) {
        case 1: return "banking"
        case 2: return "merchant-services"
        case 3: return "direct-debit"
        case 4: return "payment-gateway"
        case 5: return "ivr-call-masking"
        case 6: return "innovation-fs"
        default: return ""
      }
    },
    goBack() {
        this.$router.push({
          path: '/'+ this.cct_category +'?code=' + this.bank_transactions[0].code
        })
    },
    print () {
      var self = this
      if (this.printing) {
        setTimeout(function(){
            self.$htmlToPaper('print', null, () => {
            console.log('Printing completed or was cancelled!')
            self.printing = false
            $( "#chart-container" ).empty()
          });
            }, 1000);
      } else {
        var node = document.getElementById('report-page')
        this.convertToImage(node)
      }
    },
    convertToImage(element) {
      var chartContainer = document.getElementById('chart-container')
      var self = this
      htmlToImage.toPng(element)
        .then(function (dataUrl) {
          var img = new Image();
          img.src = dataUrl;
          chartContainer.appendChild(img)
          self.printing = true
          self.print()
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error)
        })
    },
    getFreeBankingPeriod(year, total_charge) {
      var fb_period = 0
      if (total_charge < 20000) {
        if (year == 4) fb_period = 3
        if (year == 5 || year == 6 || year == 7 || year == 8 || year == 9 || year == 10) fb_period = 6 
      } else if (total_charge > 20000 && total_charge <= 40000) {
        if (year == 4 || year == 5) fb_period = 6
        if (year == 6) fb_period = 9
        if (year == 7 || year == 8 || year == 9 || year == 10) fb_period = 12
      } else {
        if (year == 4) fb_period = 6
        if (year == 5) fb_period = 9
        if (year == 6 || year ==  7 || year == 8 || year == 9) fb_period = 12
        if (year == 10 ) fb_period = 18
      }

      this.freebankingperiod = fb_period

      return this.freebankingperiod
    },
    getFBPaddOn(year, total_charge) {
      var fb_period = 0
      if (total_charge < 20000) {
        if (this.year == 4) {
          if (year == 2 || year == 3 || year == 4) fb_period = 3
        } 
      } else if (total_charge > 20000 && total_charge <= 40000) {
        if (this.year == 4) {
          if (year == 2) fb_period = 3
          if ((year == 3) || (year == 4)) fb_period = 6
        }
        if (this.year == 5) {
          if ((year == 2) || (year == 3 )|| (year == 4) || (year == 5)) fb_period = 6
        }
        if (this.year == 6) {
          if ((year == 2) || (year == 3) || (year == 4) || (year == 5)) fb_period = 3
        }  
      } else if (total_charge > 40000) {
        
        if (this.year == 4) {
          if ((year == 2) || (year == 3) || (year == 4)) fb_period = 6
        }
        
        if (this.year == 5) {
          if ((year == 2) || (year == 3) || (year == 4)) fb_period = 3
          if (year == 5) fb_period = 9
        }
        
        if (this.year == 6) {
          if (year == 4 || year == 5) fb_period = 6
        } 
        
        if (this.year == 7) {
          if ((year == 3) || (year == 4) || (year == 5)) fb_period = 6
        } 
        
        if (this.year == 8) {
          if ((year == 2) || (year == 3) || (year == 4) || (year == 5)) fb_period = 6
        }
        
        if (this.year == 9) {
          if ((year == 2) || (year == 3) || (year == 4) || (year == 5)) fb_period = 6
        }
      }

      this.fbpadd_on_period = fb_period

      return this.fbpadd_on_period
    },
    getFBPSaving(total_charge, month) {
      if(this.freebankingperiod > 12) {
        this.fbpsavings = (total_charge/month) * 12
        this.rem_fbp = this.freebankingperiod - 12
        this.fbp_extra_savings = (total_charge/month) * this.rem_fbp
      }else{
        this.fbpsavings = (total_charge/month) * this.freebankingperiod
      }
      return this.fbpsavings
    },
    getFBAddonSaving(total_charge, month) {
      this.fbp_add_on_savings = (total_charge/month) * this.fbpadd_on_period
      return this.fbp_add_on_savings
    },
    
    getGrossSaving(current_charge,total_charge, freebankingperiod, fbpsaving) {
      var fbp = freebankingperiod;
      if(freebankingperiod>12) fbp = 12
      this.gross_savings = (fbpsaving > 0)? ((current_charge - total_charge)/12)*(12-fbp) + fbpsaving : current_charge - total_charge
      this.gross_regular_savings = current_charge - total_charge
      return this.gross_savings
    },
    
    getGrossSavingExtra(current_charge,total_charge, rem_fbp, fbpsaving) {
      this.gross_savings_extra = (fbpsaving > 0)? ((current_charge - total_charge)/12)*(12-rem_fbp) + fbpsaving : current_charge - total_charge
      return this.gross_savings_extra
    },
    getAddOnGrossSaving(current_charge,total_charge, fbpadd_on_period, fbpaddonsaving) {
      this.add_on_gross_savings = (fbpaddonsaving > 0) ? ((current_charge - total_charge)/12)*(12-fbpadd_on_period) + fbpaddonsaving : current_charge - total_charge
      return this.add_on_gross_savings
    },
    getAddOnNetSavings(gross_savings, psf_charge) {
      this.add_on_net_savings = gross_savings - (gross_savings* psf_charge)
      return this.add_on_net_savings
    },
    getNetSavings(gross_savings, psf_charge) {
      this.net_savings = gross_savings - (gross_savings * psf_charge)
      this.net_regular_savings = this.gross_regular_savings - (this.gross_regular_savings * psf_charge)
      return this.net_savings
    },
    getNetExtraSavings(gross_savings, psf_charge) {
      this.net_extra_savings = gross_savings - (gross_savings * psf_charge)
      return this.net_extra_savings
    },
    getBankTransactionById(id) {
      BankTransaction.get({params: {group_code: id}})
      .then(result => {
        if(result.data.bank_transactions)
        {                  
          for(var i=0; i<result.data.bank_transactions.length; i++) {
            let code = (result.data.bank_transactions[i].code)
            BankTransaction.show(code).then(response => {
              if(response.data.bank_transaction) {
                this.bank_transactions.push(response.data.bank_transaction)
                this.cct_category = this.getCategoryString(response.data.bank_transaction.category)
                this.getSupplierName(response.data.bank_transaction.supplier_id)
              }
              this.fillData(response.data.bank_transaction) 

            })
          }
        }
      })
    },
    getSupplierName(payload) {
      Supplier.show(payload).then(response => {
        if(response.data.supplier)
            this.supplier_names.push(response.data.supplier.name)
      })
    },
    getTransactionsByCode(code) {
      BankTransaction.show(code).then(result => {
        if(result.data.bank_transaction)
          this.transactions = result.data.bank_transaction.transactions
      })
      .catch( error => console.error('error:', error.response.data))
    },
    fillData(bank_transaction){
      
      var current = (this.cct_category == 'banking') ? this.multiplyByYear(bank_transaction.annualized_current_charge) + this.multiplyAddOnByYear(bank_transaction.annualized_current_charge) : this.multiplyByYear(bank_transaction.annualized_current_charge)
      var total = this.multiplyByYear(bank_transaction.total_charge)
      var gross = this.multiplyByYear(bank_transaction.savings)
      var net = this.multiplyByYear((bank_transaction.savings - bank_transaction.savings*this.psf_charge))
      
      if(this.cct_category == 'banking') {
        if(this.status) {
          total = (this.year == 10 && bank_transaction.annualized_current_charge > 40000) ? (this.multiplyByYear(bank_transaction.total_charge) - (this.fbpsavings + this.fbp_extra_savings)) + (this.multiplyAddOnByYear(bank_transaction.total_charge) - this.fbp_add_on_savings) : (this.multiplyByYear(bank_transaction.total_charge) - (this.fbpsavings)) + (this.multiplyAddOnByYear(bank_transaction.total_charge) - this.fbp_add_on_savings)
          gross = (this.year == 10 && bank_transaction.annualized_current_charge > 40000) ? ((this.multiplyByYear(bank_transaction.savings) - (2 * bank_transaction.savings)) + this.gross_savings + this.gross_savings_extra) + ((this.multiplyAddOnByYear(bank_transaction.savings) - bank_transaction.savings) + this.add_on_gross_savings) : ((this.multiplyByYear(bank_transaction.savings) - bank_transaction.savings) + this.gross_savings) + ((this.multiplyAddOnByYear(bank_transaction.savings) - bank_transaction.savings) + this.add_on_gross_savings)
          net = (this.year == 10 && bank_transaction.annualized_current_charge > 40000) ? ((this.multiplyByYear((this.gross_regular_savings-(this.gross_regular_savings*((0>this.gross_regular_savings)?0:this.psf_charge)))) - (2 * (this.gross_regular_savings-(this.gross_regular_savings*((0>this.gross_regular_savings)?0:this.psf_charge))))) + (this.gross_savings-(this.gross_savings*((0>this.gross_savings)?0:this.psf_charge))) + (this.gross_savings_extra-(this.gross_savings_extra*((0>this.gross_savings_extra)?0:this.psf_charge)))) + ((this.multiplyAddOnByYear((this.gross_regular_savings-(this.gross_regular_savings*((0>this.gross_regular_savings)?0:this.psf_charge)))) - (this.gross_regular_savings-(this.gross_regular_savings*((0>this.gross_regular_savings)?0:this.psf_charge)))) + (this.add_on_gross_savings-(this.add_on_gross_savings*((0>this.add_on_gross_savings)?0:this.psf_charge)))) : ((this.multiplyByYear((this.gross_regular_savings-(this.gross_regular_savings*((0>this.gross_regular_savings)?0:this.psf_charge)))) - (this.gross_regular_savings-(this.gross_regular_savings*((0>this.gross_regular_savings)?0:this.psf_charge)))) + (this.gross_savings-(this.gross_savings*((0>this.gross_savings)?0:this.psf_charge)))) + ((this.multiplyAddOnByYear((this.gross_regular_savings-(this.gross_regular_savings*((0>this.gross_regular_savings)?0:this.psf_charge)))) - (this.gross_regular_savings-(this.gross_regular_savings*((0>this.gross_regular_savings)?0:this.psf_charge)))) + (this.add_on_gross_savings-(this.add_on_gross_savings*((0>this.add_on_gross_savings)?0:this.psf_charge))))
        } else {
          total = (this.year == 10 && bank_transaction.annualized_current_charge > 40000) ? (this.multiplyByYear(bank_transaction.total_charge) - (this.fbpsavings + this.fbp_extra_savings)) : (this.multiplyByYear(bank_transaction.total_charge) - (this.fbpsavings))
          gross = (this.year == 10 && bank_transaction.annualized_current_charge > 40000) ? ((this.multiplyByYear(bank_transaction.savings) - (2 * bank_transaction.savings)) + this.gross_savings + this.gross_savings_extra) : ((this.multiplyByYear(bank_transaction.savings) - bank_transaction.savings) + this.gross_savings)
          net = (this.year == 10 && bank_transaction.annualized_current_charge > 40000) ? ((this.multiplyByYear((this.gross_regular_savings-(this.gross_regular_savings*((0>this.gross_regular_savings)?0:this.psf_charge)))) - (2 * (this.gross_regular_savings-(this.gross_regular_savings*((0>this.gross_regular_savings)?0:this.psf_charge))))) + (this.gross_savings-(this.gross_savings*((0>this.gross_savings)?0:this.psf_charge))) + (this.gross_savings_extra-(this.gross_savings_extra*((0>this.gross_savings_extra)?0:this.psf_charge)))) : ((this.multiplyByYear((this.gross_regular_savings-(this.gross_regular_savings*((0>this.gross_regular_savings)?0:this.psf_charge)))) - (this.gross_regular_savings-(this.gross_regular_savings*((0>this.gross_regular_savings)?0:this.psf_charge)))) + (this.gross_savings-(this.gross_savings*((0>this.gross_savings)?0:this.psf_charge))))
        }
      }
      
      
      
      /*var tmp = {
        labels: ["Current Annual Charges", "Lloyds bank new Cost", "Gross Savings", "Net Savings"],
        datasets: [{
          label: 'Comparison Chart for ' + this.company_name,
          barThickness: 30,
          maxBarThickness: 70,
          borderWidth: 1,
          backgroundColor: ['#ffadad','#9bf6ff','#caffbf', '#fdffb6'],
          borderColor: ['rgba(255,99,132,1)','rgba(54, 162, 235, 1)','rgba(75, 192, 192, 1)','rgba(244, 208, 63, 1)'],
          pointBorderColor: '#2554FF',
          data: [
            current,
            total,
            gross,
            net
          ]
        }],*/


      var tmp = { 
        type: 'bar',
          data: {
              labels: ["Current Annual Charges", "Lloyds bank new Cost", "Gross Savings", "Net Savings"],
              datasets: [{
                  label: 'Comparison Chart for ' + this.company_name,
                  data: [current, total, gross, net],
                  borderColor: [
                      'rgba(255,99,132,1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(244, 208, 63, 1)'
                  ],
                  backgroundColor: ['#ffadad','#9bf6ff','#caffbf', '#fdffb6'],
                  borderWidth: 1
              }]
          },
          options: {
              scales: {
                  y: {
                      beginAtZero: true
                  }
              }
          }
      }
      this.dataCollections.push(tmp)
    }
  }
}
</script>

<style scoped>
  .hidden{
    display:none;
    visibility:hidden;
  }
  .white {
    color: #ffffff;
  }
  .font_bold {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 14px;
  }

  #notes {
    background: #fdffb6;
  }
</style>