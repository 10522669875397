<template>
  <div class="product-information-section" v-if="product_specifications.length">
    <h4 class="orange-color">Features/Specifications/Benefits</h4>
    <div class="table-responsive">
      <table class="table table-borderless">
        <thead class="text-sm">
          <th>Feature/Specification</th>
          <th>Value</th>
          <th>Notes</th>
          <th>Attachments</th>
        </thead>
        <tbody>
          <tr v-for="spec in product_specifications" :key="spec.id">
            <td class="text-wrap text-sm" style="width: 50%">
              {{
                spec.specification.original_description
              }}
            </td>
            <td class="text-sm text-center">
              {{ spec.response }}
            </td>
            <td class="text-sm text-wrap" style="width: 20%">
                <!-- <div v-if="spec.notes && spec.notes.length" class="text-sm"> -->
                  <!-- <span style="font-weight:900">Description:</span> -->
                  <!-- <div class="text-wrap text-sm"> -->
                    <text-with-show-more :length="200" :string="spec.notes"></text-with-show-more>
                  <!-- </div> -->
                <!-- </div> -->
              </td>
            <td class="text-sm">
              <div v-if="spec.specification_files && spec.specification_files.length
                " class="text-sm">
                <span style="font-weight:600">Specification Document:</span><br />
                <span v-for="(files, key) in spec.specification_files" :key="key">
                  <a class="link-primary text-sm" :href="files.absolute_path" target="_blank">Download attachment {{ key +
                    1 }}
                    <i class="fa-solid fa-file-lines"></i></a><br />
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination :meta="meta" v-on:update="getSpecifications()" />
    </div>
  </div>
</template>

<script>
import { ProductSpecification } from '@/api_services/product_specification'

export default {
  props: ['product'],
  data() {
    return {
      product_specifications: [],
      loading: false,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      }
    }
  },
  watch: {
    update: {
      deep: true,
      handler() {
        this.getSpecifications()
      }
    }
  },
  created: function () {
    this.getSpecifications()
  },
  methods: {
    getSpecifications() {
      this.loading = true
      // this.product_specifications = []
      ProductSpecification.get({
        params: {
          product_id: this.product.id,
          term: this.term,
          page: this.meta.page,
          per_page: 5
        }
      })
        .then(result => {
          if (result.data.product_specifications)
            this.product_specifications = result.data.product_specifications
          this.meta = result.data.meta
        })
        .catch(error => { })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
