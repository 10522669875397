<template>
  <div>
    <!-- <choice
    :selectid="selid"
      multiple
      required
      v-model="selected_companies"
      :options="userCompanyOptions"
      :config="{
        removeItemButton: true,
        searchEnabled: false,
        duplicateItemsAllowed: false,
        searchResultLimit: 2,
        shouldSort: false,
        searchFields: ['label'],
        classNames: {
          //containerOuter: 'choiceshaha',
        }
      }"
    ></choice> -->

    <el-select v-model="selected_companies" multiple placeholder="Select" style="width: 100%;">
      <el-option v-for="(item, i) in userCompanyOptions" :key="i" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
    
  </div>
</template>

<script>
import { Company } from '@/api_services/company'
// import Choice from '@/components/common/Choice'

export default {
  props: ['user'],
  // components: { Choice },
  data() {
    return {
      selid:"usercompaniesdropdown",
      selected_companies: [],
      companies: []
    }
  },
  watch: {
    selected_companies: {
      handler(newValue) {
        console.log(this.selected_companies)
        this.$emit('update', newValue)
      },
      deep: true
    }
  },
  created: function() {
    this.selected_companies = []
    let self = this
    if (!this.user.companies) this.user.companies = []
    this.user.companies.forEach(company => {
      self.selected_companies.push(company.id.toString())
    })

    this.getCompanies()
  },
  computed: {
    userCompanyOptions() {
      return (
        this.companies?.map(c => {
          return { value: c.id.toString(), label: c.name }
        }) || []
      )
    }
  },
  methods: {
    getCompanies() {
      this.companies = []
      Company.get({ params: { per_page: 99999 } })
        .then(result => {
          if (result.data.companies) {
            this.companies = result.data.companies
          }
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching companies')
          console.error('error:', error.response.data)
        })
    }
  }
}
</script>
