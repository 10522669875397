import api from './api'

const API = '/api/supplierhub-lots'

export const SupplierhubLot = {
  get: function(params) {
    return api.get(API, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  show: function(id) {
    return api.get(`${API}/${id}`)
  },
  update: function(params) {
    return api.update(`${API}/${params.id}`, params)
  },
  delete: function(id, type) {
    return api.delete(`${API}/${id}/${type}`)
  },
  savePricing: function(id, form) {
    return api.update(`${API}/${id}/save-pricing`, form)
  },
}
