<template>
  <div v-if="lot">
    <el-drawer title="" v-model="showAdd" direction="rtl" :destroy-on-close="true" :append-to-body="true">
      <supplierhub-lot-form @close="handleSet($event)" :lot="selected" :pin_notice_id="pin_notice_id"
        :itt_framework_id="itt_framework_id" :if_pin_notice="if_pin_notice"></supplierhub-lot-form>
    </el-drawer>

    <el-drawer title="" v-model="showSupplierhubSpec" direction="rtl" size="70%" :destroy-on-close="true"
      :append-to-body="true">
      <supplierhub-lot-view @close="handleClose()" :lot="selected" :itt_framework="itt_framework"
        :pin_notice_id="pin_notice_id" :itt_framework_id="itt_framework_id" :if_pin_notice="if_pin_notice" :kind="kind"
        v-if="selected && showSupplierhubSpec"></supplierhub-lot-view>
    </el-drawer>

    <div class="row">
      <div class="col-md-12">
        <h4>{{ selected.name }}
          <el-tooltip :enterable="false" placement="top-start" v-if="allowCreate">
            <template #content>
              <span>Edit </span>
            </template>
            <a style="font-size: 12px" class="link" @click="handleEdit"><i class="fa fa-edit"></i> Edit</a>
          </el-tooltip>
        </h4>
        <div v-html="selected.short_description"></div>
        <div v-html="selected.long_description"></div>
        <hr>
        <div>
          <el-tooltip :enterable="false" placement="top-start">
            <template #content>
              <span>Specifications</span>
            </template>
            <el-button plain size="medium" type="primary" @click="handleView(0)">Specifications</el-button>
          </el-tooltip>

          <el-tooltip :enterable="false" placement="top-start">
            <template #content>
              <span>Social Values</span>
            </template>
            <el-button plain size="medium" type="primary" @click="handleView(1)">Social Values</el-button>
          </el-tooltip>
        </div>
      </div>
      <div v-allowed="'common_files,view'" class="col-md-6">
        <common-files :itt_framework="itt_framework" :lot_id="selected.id" :if_pin_notice="if_pin_notice"
          :update="update"></common-files>
      </div>
      <div v-allowed="'my_files,view'" class="col-md-6">
        <my-files :itt_framework="itt_framework" :lot_id="selected.id" :if_pin_notice="if_pin_notice"
          @updated="handleUpdate"></my-files>
      </div>

      <div v-allowed="'questions,view'" class="col-md-6">
        <public-questions-with-answers-list :itt_framework="itt_framework" :lot_id="selected.id"
          :if_pin_notice="if_pin_notice"></public-questions-with-answers-list>
      </div>

      <div class="col-md-6" v-allowed="'questions,view'">
        <my-questions-list :itt_framework="itt_framework" :lot_id="selected.id"
          :if_pin_notice="if_pin_notice"></my-questions-list>
      </div>

    </div>
  </div>
</template>

<script>


import {
  mapState
} from 'vuex'
import MyQuestionsList from '../../questions_and_answers/MyQuestionsList.vue'
import PublicQuestionsWithAnswersList from '../../questions_and_answers/PublicQuestionsWithAnswersList.vue'
import CommonFiles from '../CommonFiles.vue'
import MyFiles from '../MyFiles.vue'
import SupplierhubLotForm from './SupplierhubLotForm.vue'
import SupplierhubLotView from './SupplierhubLotView.vue'

export default {
  computed: {
    ...mapState(['IttStatuses', 'ProcurementProjectStatuses', 'ProjectFilters', 'BuyerRoleName']),
    user() {
      return this.$store.state.savedUser // get state
    }
  },
  props: {
    joined: {
      type: Boolean,
      required: false,
      default: false
    },
    allowCreate: {
      type: Boolean,
      required: false,
      default: false
    },
    itt_framework: {
      type: Object,
      required: false,
      default: null
    },
    lot: {
      type: Object,
      required: false,
      default: null
    },
    itt_framework_id: {
      type: Number,
      required: false,
      default: null
    },
    pin_notice_id: {
      type: Number,
      required: false,
      default: null
    },
    if_pin_notice: {
      type: Number,
      required: false,
      default: 0
    }
  },
  components: {
    SupplierhubLotView,
    MyQuestionsList,
    PublicQuestionsWithAnswersList,
    MyFiles,
    CommonFiles,
    SupplierhubLotForm
 
  },
  data() {
    return {
      showSupplierhubSpec: false,
      update: 0,
      kind: 0,
      showViewSpec: false,
      selected: null,
      showAdd: false
    }
  },
  created: function () {
    this.selected = Object.assign({}, this.lot)
  },
  methods: {
    handleSet(lot) {
      this.selected = Object.assign({}, lot)
      this.showAdd = false
    },
    handleView(kind) {
      this.showSupplierhubSpec = true
      this.kind = kind
    },
    handleClose() {
      this.showSupplierhubSpec = false
    },
    handleUpdate() {
      this.update += 1
    },
    handleEdit() {
      let type = "itt"
      if (this.if_pin_notice == 1) type = "pin"

      if (!this.joined) {
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to edit this lot!",
          icon: 'warning',
        })
        return false
      }

      this.selected = Object.assign({}, this.lot)
      this.showAdd = true
    },
  }
}
</script>
